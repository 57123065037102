import {RouterLink} from "@angular/router";
import {LngRoutePipe} from "@app/shared/pipes";
import {TranslateModule} from "@ngx-translate/core";
import {AppStateService} from "@app/shared/services/app-state.service";
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';

@Component({
  selector: 'sf-more-then-section',
  templateUrl: './more-then-section.component.html',
  styleUrls: ['./more-then-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    TranslateModule,
    LngRoutePipe
  ]
})
export class MoreThenSectionComponent {
  protected readonly appStateService = inject(AppStateService);
}
