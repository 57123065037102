import {NgForOf} from "@angular/common";
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {InFColor, TemplateSection, TemplateSectionItem} from "@app/shared/interfaces";

@Component({
  selector: 'sf-template-infographics',
  templateUrl: './template-infographics.component.html',
  styleUrls: ['./template-infographics.component.scss'],
  imports: [
    NgForOf
  ]
})
export class TemplateInfographicsComponent {
  @Input({required: true}) public section!: TemplateSection;
  @Input({required: true}) public sectionItem!: TemplateSectionItem;

  @Input({required: true}) colors!: InFColor;
  @Output() select$ = new EventEmitter<void>();

  public hoveredRound = 0;

  public setHoveredRound(round: number) {
    this.hoveredRound = round;
  }

  public clearHoveredRound() {
    this.hoveredRound = 0;
  }

  public onSelect(count: number) {
    this.sectionItem.skillCount = count;
    this.select$.emit();
  }

}
