import {TranslateModule} from "@ngx-translate/core";
import {TemplateService} from "@app/shared/services";
import {NzMessageService} from "ng-zorro-antd/message";
import {NgStyle, NgTemplateOutlet} from "@angular/common";
import {SectionNames, TemplateSectionTypes} from "@app/shared/enums";
import {AppStateService} from "@app/shared/services/app-state.service";
import {ContentEditableMaxLengthDirective} from "@app/shared/directives";
import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {TemplateConfigs, TemplateSettingsThemeColor, TemplateSocialInfo} from "@app/shared/interfaces";

@Component({
  selector: 'sf-social-info-sidebar',
  templateUrl: './social-info-sidebar.component.html',
  styleUrls: ['./social-info-sidebar.component.scss'],
  imports: [
    NgStyle,
    NgTemplateOutlet,
    ContentEditableMaxLengthDirective,
    TranslateModule,
  ]
})
export class SocialInfoSidebarComponent implements OnInit {
  protected readonly appStateService = inject(AppStateService);
  protected readonly templateService = inject(TemplateService);
  private readonly nzMessageService = inject(NzMessageService);

  @Input({required: true}) public template!: TemplateConfigs;
  @Input({required: true}) public tmTheme!: TemplateSettingsThemeColor;

  @Input({required: true}) public selectedItem: any = null;
  @Output() public selectedItemChange = new EventEmitter<any>();

  protected readonly TemplateSectionTypes = TemplateSectionTypes;
  private focusedElText = '';

  protected readonly SectionNames = SectionNames;

  @Output() protected openSocialInfoSettings$ = new EventEmitter<void>();

  ngOnInit() {
    if (!this.template.socialInfo.title.icon) {
      this.template.socialInfo.title.icon = {state: false, name: ''};
    }
  }

  protected onSelect(item: any) {
    this.selectedItem = item;
    this.selectedItemChange.emit(this.selectedItem);
  }

  protected onFocusEL($event: FocusEvent, text: string) {
    this.focusedElText = text;
  }

  /** On Blurs Start */

  protected onBlurSectionTitle($event: FocusEvent, info: TemplateSocialInfo) {
    info.title.text = ($event.target as HTMLHeadElement).innerHTML;

    if (this.focusedElText !== info.title.text) {
      this.saveChanges();
      this.focusedElText = '';
    }
  }

  /** On Blurs End */

  private saveChanges() {
    this.appStateService.saveChanges$.next();
  }

}
