import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {AppStateService} from "@app/shared/services/app-state.service";

export const authGuard = () => {
  const router = inject(Router);
  const appStateService = inject(AppStateService);

  if (appStateService.isAuthenticated) {
    router.navigate([appStateService.getLngRout('home')]);
    return false;
  }

  return true;
}
