import {BehaviorSubject, Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {Meta, Title} from "@angular/platform-browser";
import {NzMessageService} from "ng-zorro-antd/message";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {phosphorIcons} from "@app/shared/constants/phosphor-icons";
import {projectMessages} from "@app/shared/constants/project-messages";
import {inject, Injectable, PLATFORM_ID, Renderer2} from '@angular/core';
import {cloneObject, getClipBoardTxt, removeTags, updateCanonicalURL} from "@app/shared/helpers";
import {project_SEO_Configs, route_paths, routes_seo} from "@app/shared/constants/languages_data";
import {allCoverLetterDefaults, allTemplateDefaults} from "@app/shared/constants/ready-templates";
import {DocumentTypes, LngKeys, LocalStorageKeys, TemplateSectionTypes, TmFontSizesTypes} from "@app/shared/enums";
import {
  AllCoverLettersThemeColor,
  AllTemplatesThemeColor,
  CoverLetter,
  Payment,
  PricePreview,
  Route_seo,
  RoutePath,
  RoutePaths,
  Routes_seo,
  SEO_Config,
  Template,
  TemplateSettingsFontSize,
  TemplateSettingsThemeColor,
  TmPreviewColor,
  User,
  UserLocation
} from "@app/shared/interfaces";
import {
  ALL_COUNTRY_PRICES,
  COUNTRIES,
  FAQ_OBJ,
  FREE_BILLING_PLAN_INCLUDES,
  LANGUAGES,
  NAV_MENU,
  PREMIUM_BILLING_PLAN_INCLUDES,
  TEMPLATE_TEXT_MAX_SIZES,
  TM_FONTS
} from "@app/shared/constants";

import {template1Themes} from "@app/shared/constants/template/cv-themes/tm-1-themes";
import {template2Themes} from "@app/shared/constants/template/cv-themes/tm-2-themes";
import {template3Themes} from "@app/shared/constants/template/cv-themes/tm-3-themes";
import {template4Themes} from "@app/shared/constants/template/cv-themes/tm-4-themes";
import {template5Themes} from "@app/shared/constants/template/cv-themes/tm-5-themes";
import {template6Themes} from "@app/shared/constants/template/cv-themes/tm-6-themes";
import {template7Themes} from "@app/shared/constants/template/cv-themes/tm-7-themes";

import {coverLetter1Themes} from "@app/shared/constants/cover-letter/cover-letter-themes/cover-letter-1-themes";
import {coverLetter2Themes} from "@app/shared/constants/cover-letter/cover-letter-themes/cover-letter-2-themes";
import {coverLetter3Themes} from "@app/shared/constants/cover-letter/cover-letter-themes/cover-letter-3-themes";
import {coverLetter4Themes} from "@app/shared/constants/cover-letter/cover-letter-themes/cover-letter-4-themes";
import {coverLetter5Themes} from "@app/shared/constants/cover-letter/cover-letter-themes/cover-letter-5-themes";
import {coverLetter6Themes} from "@app/shared/constants/cover-letter/cover-letter-themes/cover-letter-6-themes";
import {coverLetter7Themes} from "@app/shared/constants/cover-letter/cover-letter-themes/cover-letter-7-themes";

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  private readonly metaService = inject(Meta);
  private readonly titleService = inject(Title);
  private readonly document = inject(DOCUMENT);
  private readonly translateService = inject(TranslateService);
  private readonly nzMessageService = inject(NzMessageService);

  private readonly platformId = inject(PLATFORM_ID);
  public readonly isBrowser = isPlatformBrowser(this.platformId);

  public readonly brandingName = 'selfcv';
  public templatesLoaderState = false;
  public getTemplateUrlLoaderState = false;
  public templateDownloadState = false;
  public templateDownloadAsImageState = false;

  /* User start */

  public user: User | null = null;
  public readonly user$ = new BehaviorSubject<User | null>(null);
  public readonly logOut$ = new Subject<void>();

  public userTemplates: Template[] = [];
  public userTemplates$ = new BehaviorSubject<Template[]>([]);

  public userLocation: UserLocation | null = null;
  public userLocationInit$ = new Subject<UserLocation>();

  /* User end */

  public couponBannerClosed = false // getItemStore<boolean>('couponBannerClosed', this.isBrowser);
  public closeCouponBanner$ = new Subject<void>();

  public templatePreview$ = new Subject<{ $event: Event, template: Template }>();

  public pricePreview: PricePreview | null = null;

  public languages = cloneObject(LANGUAGES);
  public selectedLanguage = this.languages[0]; // getItemStore<Language>('current_language', this.isBrowser) || this.languages[0];

  public readonly navMenuItems = NAV_MENU;
  public readonly countries = COUNTRIES;

  public billingPlans: Payment[] = [];
  public allCountryPrices = cloneObject(ALL_COUNTRY_PRICES);

  public readonly premiumBillingPlanIncludes = PREMIUM_BILLING_PLAN_INCLUDES;
  public readonly freeBillingPlanIncludes = FREE_BILLING_PLAN_INCLUDES;
  public isUserPlansReady = false;
  public readonly checkoutComplete$ = new Subject<void>();

  public readonly upgradeBannerState$ = new Subject<void>();
  public readonly openUpgradeBanner$ = new Subject<void>();

  public readonly templateThemeChanged$ = new Subject<void>();

  public readonly FAQObj = FAQ_OBJ;

  public tmPreviewColors: TmPreviewColor = {
    templateId_1: [1, 14, 22],
    templateId_2: [15, 10, 19],
    templateId_3: [19, 13, 26],
    templateId_4: [22, 10, 16],
    templateId_5: [29, 4, 13],
    templateId_6: [1],
    templateId_7: [1, 27, 17],
  };

  public templates: Template[] = [
    {
      templateId: 1,
      documentType: DocumentTypes.CV,
      settings: cloneObject(allTemplateDefaults.template1),
      title: 'Untitled',
      src: "assets/img/template-covers/tm-1/color-1-one-row-page-1.png",
      premium: false,
    },
    {
      templateId: 3,
      documentType: DocumentTypes.CV,
      settings: cloneObject(allTemplateDefaults.template3),
      title: 'Untitled',
      src: "assets/img/template-covers/tm-3/color-1-sidebar-page-1.png",
      premium: true,
    },
    {
      templateId: 5,
      documentType: DocumentTypes.CV,
      settings: cloneObject(allTemplateDefaults.template5),
      title: 'Untitled',
      src: "assets/img/template-covers/tm-5/color-1-two-rows-page-1.png",
      premium: true,
    },
    {
      templateId: 2,
      documentType: DocumentTypes.CV,
      settings: cloneObject(allTemplateDefaults.template2),
      title: 'Untitled',
      src: "assets/img/template-covers/tm-2/color-1-sidebar-page-1.png",
      premium: true,
    },
    {
      templateId: 6,
      documentType: DocumentTypes.CV,
      settings: cloneObject(allTemplateDefaults.template6),
      title: 'Untitled',
      src: "assets/img/template-covers/tm-6/color-1-one-row-page-1.png",
      premium: false,
    },
    {
      templateId: 4,
      documentType: DocumentTypes.CV,
      settings: cloneObject(allTemplateDefaults.template4),
      title: 'Untitled',
      src: "assets/img/template-covers/tm-4/color-1-two-rows-page-1.png",
      premium: true,
    },
    {
      templateId: 7,
      documentType: DocumentTypes.CV,
      settings: cloneObject(allTemplateDefaults.template7),
      title: 'Untitled',
      src: "assets/img/template-covers/tm-7/color-1-two-rows-page-1.png",
      premium: true,
    },
  ];

  public templatesForSlide: Template[] = [...this.templates];

  public readonly templateThemeColor: AllTemplatesThemeColor = {
    template1: template1Themes,
    template2: template2Themes,
    template3: template3Themes,
    template4: template4Themes,
    template5: template5Themes,
    template6: template6Themes,
    template7: template7Themes,
  };

  public coverLetters: Template<CoverLetter>[] = [
    {
      templateId: 1,
      documentType: DocumentTypes.COVER,
      settings: cloneObject(allCoverLetterDefaults.coverLetter1),
      title: 'Untitled',
      src: "assets/img/template-covers/cover-letter-1/color-1-cover-letter.png",
      premium: true,
    },
    {
      templateId: 3,
      documentType: DocumentTypes.COVER,
      settings: cloneObject(allCoverLetterDefaults.coverLetter3),
      title: 'Untitled',
      src: "assets/img/template-covers/cover-letter-3/color-1-cover-letter.png",
      premium: true,
    },
    {
      templateId: 5,
      documentType: DocumentTypes.COVER,
      settings: cloneObject(allCoverLetterDefaults.coverLetter5),
      title: 'Untitled',
      src: "assets/img/template-covers/cover-letter-5/color-1-cover-letter.png",
      premium: true,
    },
    {
      templateId: 2,
      documentType: DocumentTypes.COVER,
      settings: cloneObject(allCoverLetterDefaults.coverLetter2),
      title: 'Untitled',
      src: "assets/img/template-covers/cover-letter-2/color-1-cover-letter.png",
      premium: true,
    },
    {
      templateId: 4,
      documentType: DocumentTypes.COVER,
      settings: cloneObject(allCoverLetterDefaults.coverLetter4),
      title: 'Untitled',
      src: "assets/img/template-covers/cover-letter-4/color-1-cover-letter.png",
      premium: true,
    },
    {
      templateId: 6,
      documentType: DocumentTypes.COVER,
      settings: cloneObject(allCoverLetterDefaults.coverLetter6),
      title: 'Untitled',
      src: "assets/img/template-covers/cover-letter-6/color-1-cover-letter.png",
      premium: true,
    },
    {
      templateId: 7,
      documentType: DocumentTypes.COVER,
      settings: cloneObject(allCoverLetterDefaults.coverLetter7),
      title: 'Untitled',
      src: "assets/img/template-covers/cover-letter-7/color-1-cover-letter.png",
      premium: true,
    },
  ];

  public readonly coverLettersThemeColor: AllCoverLettersThemeColor = {
    template1: coverLetter1Themes,
    template2: coverLetter2Themes,
    template3: coverLetter3Themes,
    template4: coverLetter4Themes,
    template5: coverLetter5Themes,
    template6: coverLetter6Themes,
    template7: coverLetter7Themes,
  };

  public readonly fontSizes: Readonly<TemplateSettingsFontSize[]> = [
    {
      name: TmFontSizesTypes.SMALL,
      className: 'sm',

      mainTitle: 34,
      mainTitleLineHeight: 44,

      mainSubtitle: 16,
      mainSubtitleLineHeight: 24,

      socialInfo: 12,
      socialInfoLineHeight: 18,

      summaryTitle: 22,
      summaryTitleLineHeight: 32,

      summaryContent: 12,
      summaryContentLineHeight: 18,

      sectionMainTitle: 22,
      sectionMainTitleLineHeight: 32,

      sectionTitle: 16,
      sectionTitleLineHeight: 24,

      sectionSubtitle: 13,
      sectionSubtitleLineHeight: 19,

      sectionDesc: 12,
      sectionDescLineHeight: 18,

      sectionAchievement: 12,
      sectionAchievementLineHeight: 18,

      sectionAchList: 12,
      sectionAchListLineHeight: 18,

      sectionDateAndAddress: 12,
      sectionDateAndAddressLineHeight: 18,

      listSectionContent: 14,
      listSectionContentLineHeight: 20,

      sectionContent: 12,
      sectionContentLineHeight: 18

    },
    {
      name: TmFontSizesTypes.MEDIUM,
      className: 'md',

      mainTitle: 36,
      mainTitleLineHeight: 44,

      mainSubtitle: 16,
      mainSubtitleLineHeight: 24,

      socialInfo: 13,
      socialInfoLineHeight: 19.5,

      summaryTitle: 22,
      summaryTitleLineHeight: 32,

      summaryContent: 14,
      summaryContentLineHeight: 21,

      sectionMainTitle: 22,
      sectionMainTitleLineHeight: 32,

      sectionTitle: 16,
      sectionTitleLineHeight: 24,

      sectionSubtitle: 15,
      sectionSubtitleLineHeight: 22.5,

      sectionDesc: 14,
      sectionDescLineHeight: 21,

      sectionAchievement: 14,
      sectionAchievementLineHeight: 21,

      sectionAchList: 14,
      sectionAchListLineHeight: 21,

      sectionDateAndAddress: 13,
      sectionDateAndAddressLineHeight: 19.5,

      listSectionContent: 14,
      listSectionContentLineHeight: 21,

      sectionContent: 14,
      sectionContentLineHeight: 21

    },
    {
      name: TmFontSizesTypes.LARGE,
      className: 'lr',

      mainTitle: 36,
      mainTitleLineHeight: 44,

      mainSubtitle: 16,
      mainSubtitleLineHeight: 24,

      socialInfo: 14,
      socialInfoLineHeight: 21,

      summaryTitle: 22,
      summaryTitleLineHeight: 32,

      summaryContent: 15,
      summaryContentLineHeight: 22.5,

      sectionMainTitle: 22,
      sectionMainTitleLineHeight: 32,

      sectionTitle: 16,
      sectionTitleLineHeight: 24,

      sectionSubtitle: 15,
      sectionSubtitleLineHeight: 22.5,

      sectionDesc: 15,
      sectionDescLineHeight: 22.5,

      sectionAchievement: 15,
      sectionAchievementLineHeight: 22.5,

      sectionAchList: 15,
      sectionAchListLineHeight: 22.5,

      sectionDateAndAddress: 15,
      sectionDateAndAddressLineHeight: 22.5,

      listSectionContent: 15,
      listSectionContentLineHeight: 22.5,

      sectionContent: 15,
      sectionContentLineHeight: 22.5
    },
  ];
  public templateCurrentFontSize = this.fontSizes.find((sizes) => sizes.name == TmFontSizesTypes.MEDIUM) as TemplateSettingsFontSize;
  public coverCurrentFontSize = this.fontSizes.find((sizes) => sizes.name == TmFontSizesTypes.MEDIUM) as TemplateSettingsFontSize;

  public templateFonts = TM_FONTS;
  public readonly phosphorIcons: Readonly<{ name: string }[]> = phosphorIcons;

  public readonly workExpList = [
    TemplateSectionTypes.WORK_EXPERIENCE,
    TemplateSectionTypes.EDUCATION,
    TemplateSectionTypes.TEACHING,
    TemplateSectionTypes.VOLUNTEER,
    TemplateSectionTypes.AWARDS,
    TemplateSectionTypes.CONFERENCES_AND_COURSES,
    TemplateSectionTypes.ACHIEVEMENTS,
    TemplateSectionTypes.CERTIFICATES,
    TemplateSectionTypes.ORGANIZATIONS
  ];

  public readonly listSectionsList = [
    TemplateSectionTypes.SKILLS,
    TemplateSectionTypes.SOFT_SKILLS,
    TemplateSectionTypes.LANGUAGES,
    TemplateSectionTypes.INTERESTS,
    TemplateSectionTypes.CAUSES,
    TemplateSectionTypes.AREAS_OF_EXPERTISE,
  ];

  /* TODO consider this later */
  public editorState$ = new Subject();
  public editorBannerState = false;

  public readonly textMaxSizes = TEMPLATE_TEXT_MAX_SIZES;

  /* TODO fix later */
  public readonly skipKeys: Readonly<string[]> = ['Backspace', 'Delete'];
  public readonly skipMessageKeys: Readonly<string[]> = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Control'];
  public readonly descSectionMaxHeight = 210;

  public readonly saveChanges$ = new Subject<void>();

  /** SEO Objects start */

  public readonly SEO_Configs = project_SEO_Configs;
  protected routes_seo = routes_seo;
  public readonly route_paths = route_paths;
  public dateTime = new Date().getTime();

  /** SEO Objects end */

  /** Auth Configs Start */

  public authToken = '';
  public isAuthenticated = false;

  /** Auth Configs End */

  constructor() {
  }

  public setLng(key: LngKeys) {
    this.selectedLanguage = this.languages.find((lng) => lng.key === key) || this.languages[0];
    this.translateService.use(key);
    // setItemStore('current_language', key);
  }

  public onPrintPage() {
    return location.pathname.includes('template-print');
  }

  public setTemplateCurrentFontSize(size: TmFontSizesTypes) {
    this.templateCurrentFontSize = this.fontSizes.find((sizes) => sizes.name == size) as TemplateSettingsFontSize;
  }

  public setCoverCurrentFontSize(size: TmFontSizesTypes) {
    this.coverCurrentFontSize = this.fontSizes.find((sizes) => sizes.name == size) as TemplateSettingsFontSize;
  }

  public onPastElementText($event: ClipboardEvent, size: number, elHeight?: number) {
    $event.preventDefault();

    const HTMLEl = ($event.target as HTMLElement);
    const text = removeTags(getClipBoardTxt($event));

    if (HTMLEl.innerHTML.length + text?.length > size) {
      $event.preventDefault();
      this.nzMessageService.info(this.translateService.instant(projectMessages.tooLongText));
      return;
    }

    if (elHeight) {
      if (HTMLEl.clientHeight > elHeight) {
        $event.preventDefault();
        this.nzMessageService.info(this.translateService.instant(projectMessages.tooBigSize));
      }
    }

    document.execCommand('inserttext', false, text);

    // setTimeout(() => this.changeDetectorRef.markForCheck());
    this.saveChanges();
  }

  public saveChanges() {
    this.saveChanges$.next();
  }

  public getLngRout(path: string) {
    const currentLang = this.translateService.currentLang as keyof RoutePaths || LngKeys.EN;
    return this.route_paths[currentLang][path as keyof RoutePath];
  }

  /** Add new CV part logic start */

  public getTmColors(template: Template) {

    console.log('template', template);

    const colors: any[] = [];
    const templateId = template.templateId || 1;

    switch (template.documentType) {
      case DocumentTypes.CV: {
        const themeColorList: TemplateSettingsThemeColor[] =
          Object.values(this.templateThemeColor['template' + templateId as keyof AllTemplatesThemeColor]);

        const colorIds = this.tmPreviewColors['templateId_' + templateId as keyof TmPreviewColor];

        colorIds.forEach((id: number) => {
          const color = themeColorList.find((item) => item.id === id);
          if (color) {
            colors.push(color);
          }
        });
        break;
      }
      case DocumentTypes.COVER: {
        const themeColorList: TemplateSettingsThemeColor[] =
          Object.values(this.coverLettersThemeColor['template' + templateId as keyof AllCoverLettersThemeColor]);

        const colorIds = this.tmPreviewColors['templateId_' + templateId as keyof TmPreviewColor];

        colorIds.forEach((id: number) => {
          const color = themeColorList.find((item) => item.id === id);
          if (color) {
            colors.push(color);
          }
        });
        break;
      }
    }

    return colors;
  }

  /** Add new CV part logic end */

  public getCurrentCountyPlan() {
    let plan = this.allCountryPrices.find((item) => item.country === this.userLocation?.country);

    if (!plan) {
      plan = this.allCountryPrices.find((item) => item.country === 'US')!;
    }

    return plan;
  }

  public addMetaTags(project: SEO_Config, renderer: Renderer2) {
    // const title =  project.title || project.og.title;
    const desc = project.description || project.og.desc;
    const imgUrl = 'https://selfcv.s3.eu-central-1.amazonaws.com/utils/main-banner.png';
    const url = project.url;

    // this.titleService.setTitle(project.title);

    /* Update meta tags */
    this.metaService.updateTag({name: 'description', content: project.description});
    this.metaService.updateTag({name: 'keywords', content: project.keywords});

    /* Schema.org markup for Google+ */
    /** Interops are duplicated not replaces (Bug) */
    // this.metaService.updateTag({itemprop: 'name', content: title});
    // this.metaService.updateTag({itemprop: 'description', content: desc});
    // this.metaService.updateTag({itemprop: 'image', content: imgUrl});

    /*  Open Graph data  */
    // this.metaService.updateTag({property: 'og:title', content: title});
    this.metaService.updateTag({property: 'og:locale', content: project.og.local});
    this.metaService.updateTag({property: 'og:description', content: desc});
    this.metaService.updateTag({property: 'og:url', content: url});
    this.metaService.updateTag({property: 'og:image', content: imgUrl});
    this.metaService.updateTag({property: 'og:image:alt', content: 'selfcv'});

    /*  Twitter Card data  */
    this.metaService.updateTag({name: 'twitter:site', content: url});
    this.metaService.updateTag({name: 'twitter:url', content: url});
    // this.metaService.updateTag({name: 'twitter:title', content: title});
    this.metaService.updateTag({name: 'twitter:description', content: desc});
    this.metaService.updateTag({property: 'twitter:image:src', content: imgUrl});

    /* Other */
    this.metaService.updateTag({name: 'msapplication-TileImage', content: imgUrl});

    // updateCanonicalURL(url, this.document, renderer);
  }

  public setHtmlLangAttribute(lang: string, renderer: Renderer2) {
    // Update the <html> tag's lang attribute
    renderer.setAttribute(this.document.documentElement, 'lang', lang);
  }

  public setPageSeo(page: string, renderer: Renderer2) {
    const currentLang = this.translateService.currentLang as keyof Routes_seo || LngKeys.EN;
    const route_seo = this.routes_seo[currentLang][page as keyof Route_seo];
    const title = route_seo.title;
    const canonical = route_seo.canonical;

    this.titleService.setTitle(title);
    this.metaService.updateTag({property: 'og:title', content: title});
    this.metaService.updateTag({name: 'twitter:title', content: title});

    updateCanonicalURL(canonical, this.document, renderer);
  }

  /** Auth Configs */
  public init_auth_token() {
    const jwtAccessToken = localStorage.getItem(LocalStorageKeys.jwtAccessToken) || '';

    if (jwtAccessToken) {
      this.authToken = JSON.parse(jwtAccessToken);
    }

    this.isAuthenticated = this.authToken.length > 0;
  }

}
