import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ContactUs} from "@app/shared/interfaces";

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  private readonly httpClient = inject(HttpClient);

  constructor() {
  }

  public contactUs(data: Partial<ContactUs>) {
    return this.httpClient.post<{ success: boolean }>('contact-us', data);
  }

}
