import {cloneObject} from "@app/shared/helpers";
import {CoverLetter} from "@app/shared/interfaces";
import {coverLetterCommonConfigs} from "@app/shared/constants";

export const coverLetter7: CoverLetter = {
  templateId: 7,
  forTemplate: 7,
  templateTopPartType: 6,
  ...cloneObject(coverLetterCommonConfigs)
};

