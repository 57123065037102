import {AppStateService} from "@app/shared/services/app-state.service";
import {NgStyle, NgSwitch, NgSwitchCase, NgTemplateOutlet} from "@angular/common";
import {TemplateConfigs, TemplateSettingsThemeColor} from "@app/shared/interfaces";
import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {TmSummaryComponent} from "@app/templates/componenets/tm-summary/tm-summary.component";
import {TmNamePartComponent} from "@app/templates/componenets/tm-name-part/tm-name-part.component";
import {TemplateImgComponent} from "@app/templates/componenets/template-img/template-img.component";
import {TmSocialInfoComponent} from "@app/templates/componenets/tm-social-info/tm-social-info.component";

@Component({
  selector: 'sf-tm-top-part',
  templateUrl: './tm-top-part.component.html',
  styleUrls: ['./tm-top-part.component.scss'],
  imports: [
    TmSummaryComponent,
    TmNamePartComponent,
    NgTemplateOutlet,
    NgStyle,
    TemplateImgComponent,
    NgSwitch,
    NgSwitchCase,
    TmSocialInfoComponent,
  ]
})
export class TmTopPartComponent implements OnInit {
  protected appStateService = inject(AppStateService);

  @Input({required: true}) public template!: TemplateConfigs;
  @Input({required: true}) public tmTheme!: TemplateSettingsThemeColor;
  @Input({required: true}) public forPrint!: boolean;
  @Input({required: true}) public selectedItem: any = null;
  @Output() public selectedItemChange = new EventEmitter<any>();

  @Output() public settingsSelected$ = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }

  protected onSelect(item: any) {
    this.selectedItem = item;
    this.selectedItemChange.emit(this.selectedItem);
  }

}
