import {NgIf, NgStyle} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {NzMessageService} from "ng-zorro-antd/message";
import {TemplateLogicService} from "@app/shared/services";
import {TmEditorComponent} from "@app/templates/componenets";
import {AppStateService} from "@app/shared/services/app-state.service";
import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {TemplateConfigs, TemplateConfigsSummary, TemplateSettingsThemeColor} from "@app/shared/interfaces";
import {ClickableLinksDirective, ContentEditableMaxLengthDirective, CtrlADirective} from "@app/shared/directives";

@Component({
  selector: 'sf-tm-summary',
  templateUrl: './tm-summary.component.html',
  styleUrls: ['./tm-summary.component.scss'],
  imports: [
    NgIf,
    TmEditorComponent,
    CtrlADirective,
    NgStyle,
    ClickableLinksDirective,
    ContentEditableMaxLengthDirective,
    TranslateModule,
  ]
})
export class TmSummaryComponent implements OnInit {
  protected readonly appStateService = inject(AppStateService);
  protected readonly nzMessageService = inject(NzMessageService);
  protected readonly templateLogicService = inject(TemplateLogicService);

  @Input({required: true}) public template!: TemplateConfigs;
  @Input({required: true}) public tmTheme!: TemplateSettingsThemeColor;
  @Input() hasUnderLine = true;

  @Input({required: true}) public selectedItem: any = null;
  @Output() public selectedItemChange = new EventEmitter();

  @Input() public insideSidebar = false;

  private focusedElText = '';

  constructor() {
  }

  ngOnInit() {
  }

  protected onSelect(item: any) {
    this.selectedItem = item;
    this.selectedItemChange.emit(this.selectedItem);
  }

  protected onFocusEL($event: FocusEvent, text: string) {
    this.focusedElText = text;
  }

  protected onBlurSummaryTitle($event: FocusEvent, section: TemplateConfigsSummary) {
    section.title.text = ($event.target as HTMLHeadElement).innerHTML;

    if (this.focusedElText !== section.title.text) {
      this.saveChanges();
      this.focusedElText = '';
    }
  }

  protected onBlurTmSummaryDesc($event: FocusEvent) {
    this.template.summary.desc.text = ($event.target as HTMLParagraphElement).innerHTML;

    if (this.focusedElText !== this.template.summary.desc.text) {
      this.saveChanges();
      this.focusedElText = '';
    }
  }

  protected onEnterDesc($event: Event) {
    const HTMLEl = $event.target as HTMLParagraphElement;

    if (!HTMLEl.innerText.length) {
      $event.preventDefault();
    }

    /*if (HTMLEl.clientHeight > this.appStateService.descSectionMaxHeight) {
      $event.preventDefault();
      this.nzMessageService.info('Too big size for section');
    }*/

    setTimeout(() => this.templateLogicService.splitToPages(this.template));
  }

  protected onBackspaceSummary($event: Event) {
    this.templateLogicService.descDeleteSubject.next(($event.target as HTMLElement).innerText);
  }

  protected handleCtrlA(item: any) {
    this.appStateService.editorState$.next(item);
  }

  protected handleMouseUp(item: any) {
    setTimeout(() => {
      this.appStateService.editorState$.next(item);
    });
  }

  private saveChanges() {
    this.appStateService.saveChanges$.next();
  }

}

