import {TemplateTypes} from "@app/shared/enums";
import {NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {concatMap, from, interval, take} from "rxjs";
import {NzPopoverDirective} from "ng-zorro-antd/popover";
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import {onChangeTemplateType} from "@app/shared/helpers";
import {TemplateLogicService} from "@app/shared/services";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Component, DestroyRef, inject, Input} from '@angular/core';
import {AppStateService} from "@app/shared/services/app-state.service";
import {TemplateConfigs, TemplateSection} from "@app/shared/interfaces";

interface TemplateType {
  src: string;
  name: string;
  type: TemplateTypes
}

@Component({
  selector: 'sf-tm-layouts',
  imports: [
    NgOptimizedImage,
    NzPopoverDirective,
    NzTooltipDirective,
    TranslateModule
  ],
  templateUrl: './tm-layouts.component.html',
  styleUrl: './tm-layouts.component.scss'
})
export class TmLayoutsComponent {
  private readonly destroyRef = inject(DestroyRef);
  private readonly appStateService = inject(AppStateService);
  private readonly templateLogicService = inject(TemplateLogicService);

  protected templateTypes: TemplateType[] = [
    {
      src: 'assets/img/tm-layouts/tm-layout-sidebar-b.svg',
      name: 'tooltips.sidebar',
      type: TemplateTypes.SIDEBAR
    },
    {
      src: 'assets/img/tm-layouts/tm-layout-one-row-b.svg',
      name: 'tooltips.one_column',
      type: TemplateTypes.ONE_ROW
    },
    {
      src: 'assets/img/tm-layouts/tm-layout-two-rows-b.svg',
      name: 'tooltips.two_columns',
      type: TemplateTypes.TWO_ROWS
    }
  ];

  @Input({required: true}) public template!: TemplateConfigs;

  constructor() {
  }

  protected onChangeTemplateType(type: TemplateTypes) {
    onChangeTemplateType(this.template, type, (sections) => {
      this.splitToPagesAfterTypeChange(sections);
    });

    this.saveChanges();
  }

  private splitToPagesAfterTypeChange(sections: TemplateSection[]) {
    from(sections)
      .pipe(
        concatMap(() => interval(100).pipe(take(1))),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.templateLogicService.splitToPages(this.template);
      });
  }

  protected saveChanges() {
    this.appStateService.saveChanges$.next();
  }

}
