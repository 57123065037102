import {SingleCoverLetterThemeColor} from "@app/shared/interfaces";

export const coverLetter7Themes: SingleCoverLetterThemeColor = {
  theme1: {
    id: 1,

    fontColor: '#303030',
    emptyImgColor: '#4B57DC',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#4B57DC',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#4B57DC',
    countryColor: '#4B57DC',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2A3958',
    demoColor2: '#4B57DC',

    linkColor: '#4B57DC'
  },
  theme2: {
    id: 2,

    fontColor: '#303030',
    emptyImgColor: '#69748B',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#69748B',
    countryColor: '#69748B',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#374259',
    demoColor2: '#69748B',

    linkColor: '#69748B'
  },
  theme3: {
    id: 3,

    fontColor: '#303030',
    emptyImgColor: '#647982',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#647982',
    countryColor: '#647982',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#324750',
    demoColor2: '#647982',

    linkColor: '#647982'
  },
  theme4: {
    id: 4,

    fontColor: '#303030',
    emptyImgColor: '#808B8F',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#808B8F',
    countryColor: '#808B8F',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#4E595D',
    demoColor2: '#808B8F',

    linkColor: '#808B8F'
  },
  theme5: {
    id: 5,

    fontColor: '#303030',
    emptyImgColor: '#828293',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#828293',
    countryColor: '#828293',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#505061',
    demoColor2: '#828293',

    linkColor: '#828293'
  },
  theme6: {
    id: 6,

    fontColor: '#303030',
    emptyImgColor: '#A49393',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#A49393',
    countryColor: '#A49393',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#5B5054',
    demoColor2: '#A49393',

    linkColor: '#A49393'
  }, // template 5 color
  theme7: {
    id: 7,

    fontColor: '#303030',
    emptyImgColor: '#43779F',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#43779F',
    countryColor: '#43779F',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#1B4F77',
    demoColor2: '#43779F',

    linkColor: '#43779F'
  },
  theme8: {
    id: 8,

    fontColor: '#303030',
    emptyImgColor: '#2983BA',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#2983BA',
    countryColor: '#2983BA',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#005188',
    demoColor2: '#2983BA',

    linkColor: '#2983BA'
  },
  theme9: {
    id: 9,

    fontColor: '#303030',
    emptyImgColor: '#6C6EA5',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#6C6EA5',
    countryColor: '#6C6EA5',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#3A3C73',
    demoColor2: '#6C6EA5',

    linkColor: '#6C6EA5'
  },
  theme10: {
    id: 10,

    fontColor: '#303030',
    emptyImgColor: '#5A79C7',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#5A79C7',
    countryColor: '#5A79C7',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#39559A',
    demoColor2: '#5A79C7',

    linkColor: '#5A79C7'
  },
  theme11: {
    id: 11,

    fontColor: '#303030',
    emptyImgColor: '#516D92',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#516D92',
    countryColor: '#516D92',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#1F3B60',
    demoColor2: '#516D92',

    linkColor: '#516D92'
  },
  theme12: {
    id: 12,

    fontColor: '#303030',
    emptyImgColor: '#3C7680',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#3C7680',
    countryColor: '#3C7680',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#0A444E',
    demoColor2: '#3C7680',

    linkColor: '#3C7680'
  },
  theme13: {
    id: 13,

    fontColor: '#303030',
    emptyImgColor: '#527F7A',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#527F7A',
    countryColor: '#527F7A',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2A5752',
    demoColor2: '#527F7A',

    linkColor: '#527F7A'
  },
  theme14: {
    id: 14,

    fontColor: '#303030',
    emptyImgColor: '#519097',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#519097',
    countryColor: '#519097',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#29686F',
    demoColor2: '#519097',

    linkColor: '#519097'
  },
  theme15: {
    id: 15,

    fontColor: '#303030',
    emptyImgColor: '#728686',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#728686',
    countryColor: '#728686',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#405454',
    demoColor2: '#728686',

    linkColor: '#728686'
  },
  theme16: {
    id: 16,

    fontColor: '#303030',
    emptyImgColor: '#7E8A7B',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#7E8A7B',
    countryColor: '#7E8A7B',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#606C5D',
    demoColor2: '#7E8A7B',

    linkColor: '#7E8A7B'
  },
  theme17: {
    id: 17,

    fontColor: '#303030',
    emptyImgColor: '#2B2E35',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#ACCEC8',
    countryColor: '#ACCEC8',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2B2E35',
    demoColor2: '#ACCEC8',

    linkColor: '#ACCEC8'
  },   // template 2 color
  theme18: {
    id: 18,

    fontColor: '#303030',
    emptyImgColor: '#5E4E3F',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#908071',
    countryColor: '#908071',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#5E4E3F',
    demoColor2: '#908071',

    linkColor: '#908071'
  },
  theme19: {
    id: 19,

    fontColor: '#303030',
    emptyImgColor: '#66423C',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#8E6A64',
    countryColor: '#8E6A64',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#66423C',
    demoColor2: '#8E6A64',

    linkColor: '#8E6A64'
  },
  theme20: {
    id: 20,

    fontColor: '#303030',
    emptyImgColor: '#572B43',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#7F536B',
    countryColor: '#7F536B',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#572B43',
    demoColor2: '#7F536B',

    linkColor: '#7F536B'
  },
  theme21: {
    id: 21,

    fontColor: '#303030',
    emptyImgColor: '#303030',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#626262',
    countryColor: '#626262',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#303030',
    demoColor2: '#626262',

    linkColor: '#626262'
  },  // black color
  theme22: {
    id: 22,

    fontColor: '#303030',
    emptyImgColor: '#8C3868',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#BE6A9A',
    countryColor: '#BE6A9A',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#8C3868',
    demoColor2: '#BE6A9A',

    linkColor: '#BE6A9A'
  },
  theme23: {
    id: 23,

    fontColor: '#303030',
    emptyImgColor: '#6B3A56',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#9D6C88',
    countryColor: '#9D6C88',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#6B3A56',
    demoColor2: '#9D6C88',

    linkColor: '#9D6C88'
  },
  theme24: {
    id: 24,

    fontColor: '#303030',
    emptyImgColor: '#B05CAC',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#E0589E',
    countryColor: '#E0589E',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#B05CAC',
    demoColor2: '#E0589E',

    linkColor: '#E0589E'
  },  // template 4 color
  theme25: {
    id: 25,

    fontColor: '#303030',
    emptyImgColor: '#4C3551',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#8C6194',
    countryColor: '#8C6194',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#4C3551',
    demoColor2: '#8C6194',

    linkColor: '#8C6194'
  },  // template 3 color
  theme26: {
    id: 26,

    fontColor: '#303030',
    emptyImgColor: '#736195',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#9B89BD',
    countryColor: '#9B89BD',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#736195',
    demoColor2: '#9B89BD',

    linkColor: '#9B89BD'
  },
  theme27: {
    id: 27,

    fontColor: '#303030',
    emptyImgColor: '#672B61',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#995D93',
    countryColor: '#995D93',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#672B61',
    demoColor2: '#995D93',

    linkColor: '#995D93'
  },
  theme28: {
    id: 28,

    fontColor: '#303030',
    emptyImgColor: '#58396C',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#8A6B9E',
    countryColor: '#8A6B9E',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#58396C',
    demoColor2: '#8A6B9E',

    linkColor: '#8A6B9E'
  },
  theme29: {
    id: 29,

    fontColor: '#303030',
    emptyImgColor: '#65758D',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',
    topPartBgColor: '#fff',

    subTitleFontColor: '#303030',
    subTitleFontEditModeColor: '#303030',
    subTitleBGColor: '#fff',

    dateColor: '#65758D',
    countryColor: '#65758D',

    socialInfo: {
      bgColor: '#F9FAFB',
      fontColor: '#303030',
      borderColor: '#F9FAFB',
    },

    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#3D4D65',
    demoColor2: '#65758D',

    linkColor: '#65758D'
  },
};

