import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {inject} from '@angular/core';
import {AuthService} from "@app/shared/services";
import {AppStateService} from "@app/shared/services/app-state.service";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

export const dashboardGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

  const router = inject(Router);
  const authService = inject(AuthService);
  const appStateService = inject(AppStateService);

  if (!appStateService.isAuthenticated) {
    router.navigate(
      [appStateService.getLngRout('auth')],
      {queryParams: {...route.queryParams, returnUrl: state.url}}
    );
    return false;
  }

  if (appStateService.user) {
    return true
  }

  return authService.getMe().pipe(map(() => true));
}
