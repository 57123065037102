import {NzModalModule} from "ng-zorro-antd/modal";
import {TranslateModule} from "@ngx-translate/core";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {AppStateService} from "@app/shared/services/app-state.service";
import {Component, inject, Input, OnInit, Renderer2} from '@angular/core';
import {PaymentBannerComponent, PremiumPlansComponent} from "@app/shared/ui-kits";

@Component({
  selector: 'sf-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss'],
  imports: [
    NzDrawerModule,
    NzModalModule,
    PaymentBannerComponent,
    PremiumPlansComponent,
    TranslateModule
  ]
})
export class ChoosePlanComponent implements OnInit {
  private readonly renderer2 = inject(Renderer2);
  protected readonly appStateService = inject(AppStateService);

  @Input() public title = 'pricing_page.plans';

  ngOnInit() {
    if (this.title) {
      this.appStateService.setPageSeo('account_choose_plan', this.renderer2);
    }
  }

}
