import {Component} from '@angular/core';
import {NzSkeletonModule} from "ng-zorro-antd/skeleton";

@Component({
    selector: 'sf-skeleton',
    templateUrl: './skeleton.component.html',
    styleUrls: ['./skeleton.component.scss'],
    imports: [
        NzSkeletonModule
    ]
})
export class SkeletonComponent {
}
