import {Router} from "@angular/router";
import {finalize} from "rxjs/operators";
import {NzSelectModule} from "ng-zorro-antd/select";
import {SfTrimDirective} from "@app/shared/directives";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {DeleteUserReason, User} from "@app/shared/interfaces";
import {AuthService, UserService} from "@app/shared/services";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {NzAutocompleteModule} from "ng-zorro-antd/auto-complete";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {AppStateService} from "@app/shared/services/app-state.service";
import {NzMenuDirective, NzMenuItemComponent} from "ng-zorro-antd/menu";
import {NzMessageModule, NzMessageService} from "ng-zorro-antd/message";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {NzModalModule, NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {ImageCropComponent, UserDeleteReasonComponent} from "@app/shared/modals";
import {CouponBannerComponent, PaymentBannerComponent} from "@app/shared/ui-kits";
import {NzDropDownDirective, NzDropdownMenuComponent} from "ng-zorro-antd/dropdown";
import {Component, DestroyRef, ElementRef, inject, OnInit, Renderer2, ViewChild} from '@angular/core';

@Component({
  selector: 'sf-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss'],
  imports: [
    NgIf,
    NgForOf,
    NgOptimizedImage,
    ReactiveFormsModule,
    SfTrimDirective,
    NzDatePickerModule,
    NzSelectModule,
    NzAutocompleteModule,
    NzModalModule,
    NzMessageModule,
    PaymentBannerComponent,
    NzDropDownDirective,
    NzDropdownMenuComponent,
    NzMenuDirective,
    NzMenuItemComponent,
    CouponBannerComponent,
    TranslateModule
  ]
})
export class UserPageComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly renderer2 = inject(Renderer2);
  private readonly destroyRef = inject(DestroyRef);
  public readonly userService = inject(UserService);
  public readonly authService = inject(AuthService);
  private readonly formBuilder = inject(FormBuilder);
  public readonly nzModalService = inject(NzModalService);
  public readonly appStateService = inject(AppStateService);
  private readonly translateService = inject(TranslateService);
  private readonly nzMessageService = inject(NzMessageService);

  public filteredCountries = this.appStateService.countries;

  public readonly userFrom = this.formBuilder.group({
    fullName: [null, [Validators.required, Validators.maxLength(128)]],
    phoneNumber: [null, [Validators.maxLength(128)]],
    phoneCode: [null],
    birthDate: [null],
    country: [null, [Validators.maxLength(128)]],
    city: [null, [Validators.maxLength(128)]],
    subscribeToNotifications: [false]
  });

  public initialBirthDayValue = new Date();

  @ViewChild('InputRef') protected readonly InputRef!: ElementRef<HTMLInputElement>;

  public ngOnInit(): void {
    this.appStateService.setPageSeo('account_user', this.renderer2);

    this.initialBirthDayValue.setFullYear(2000, 0, 1);
    this.appStateService.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.userFrom.patchValue(structuredClone(res) as any);
      });
  }

  public disabledDate(current: Date) {
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 110, today.getMonth(), today.getDate());
    const maxDate = new Date(today.getFullYear() - 16, today.getMonth(), today.getDate());
    return current.getTime() < minDate.getTime() || current.getTime() > maxDate.getTime();
  }

  public onCountryChange(value: string): void {
    this.filteredCountries = this.appStateService.countries
      .filter((option) => option.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public onSubmit(): void {
    if (this.userFrom.valid) {
      this.updateUser();
    }
  }

  private updateUser(): void {
    this.userService.updateUser(this.userFrom.value as any)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (user: User) => {
          [user.firstName, user.lastName] = user.fullName.split(' ')
          this.appStateService.user = user;
          this.appStateService.user$.next(user);
          this.nzMessageService.success(
            this.translateService.instant('project_messages.user_successfully_updated')
          );
        },
        error: () => {
          this.nzMessageService.error(
            this.translateService.instant('project_messages.error_update_user')
          );
        }
      });
  }

  public onDiscardChanges(): void {
    this.userFrom.reset();
    this.userFrom.patchValue(structuredClone(this.appStateService.user) as any);
  }

  public onSelectImage(file: Event): void {
    const modalRef = this.nzModalService.create({
      nzTitle: this.translateService.instant('project_messages.image_upload'),
      nzContent: ImageCropComponent,
      nzCentered: true,
      nzFooter: null
    });

    const component = modalRef.componentInstance;
    component!.imageChangedEvent = file;

    console.log('file', file);

    modalRef.afterClose
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        (file.target as HTMLInputElement).value = '';
      });

    component?.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        component!.loadingState = true;
        (file.target as HTMLInputElement).value = '';
        this.updateUserImage(res.blob as Blob, component);
      });
  }

  private updateUserImage(blob: Blob, component: ImageCropComponent): void {
    this.userService.updateUserImage(this.appStateService.user!.id || '', blob)
      .pipe(
        finalize(() => component.loadingState = false),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((r: { src: string }) => {
        this.appStateService.user!.image = r.src;
        this.appStateService.user$.next(this.appStateService.user);
        this.nzModalService.closeAll();
      });
  }

  public deleteUserImage(): void {
    this.userService.deleteUserImage(this.appStateService.user!.id || '')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.appStateService.user!.image = '';
        this.appStateService.user$.next(this.appStateService.user);
      });
  }

  public onDeleteUser(): void {
    const modalRef = this.nzModalService.create({
      nzContent: UserDeleteReasonComponent,
      nzCentered: true,
      nzFooter: null
    });

    modalRef.componentInstance?.close$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        modalRef.close()
      });

    modalRef.componentInstance?.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((reason: DeleteUserReason) => {
        modalRef.componentInstance!.deleteBtnLoadingState = true;
        this.deleteUser({id: this.appStateService.user!.id, ...reason}, modalRef);
      });
  }

  private deleteUser(reason: DeleteUserReason & {
    id?: string
  }, modalRef: NzModalRef<UserDeleteReasonComponent>) {
    this.userService.deleteUser(reason)
      .pipe(
        finalize(() => modalRef.componentInstance!.deleteBtnLoadingState = false),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: () => {
          this.authService.signOut();
          this.appStateService.user = null;
          this.appStateService.user$.next(null);
          this.appStateService.userTemplates = [];
          this.appStateService.userTemplates$.next([]);
          this.nzModalService.closeAll();

          this.router.navigate([this.appStateService.getLngRout('home')])
            .then(() => {
              this.nzMessageService.info(
                this.translateService.instant('project_messages.user_successfully_deleted')
              );
            });
        },
        error: () => {
          this.nzMessageService.error(
            this.translateService.instant('project_messages.error_deleting_user')
          );
        }
      });
  }

}
