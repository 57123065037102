import {RouterLink} from "@angular/router";
import {LngRoutePipe} from "@app/shared/pipes";
import {TranslateModule} from "@ngx-translate/core";
import {Component, inject, OnInit, Renderer2} from '@angular/core';
import {AppStateService} from "@app/shared/services/app-state.service";
import {
  FAQSectionComponent,
  MoreThenSectionComponent,
  ResumeExamplesSectionComponent,
  WhyUseUsSectionComponent
} from '@app/layouts/main-layout/pages/sections';

@Component({
  selector: 'sf-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  imports: [
    MoreThenSectionComponent,
    ResumeExamplesSectionComponent,
    WhyUseUsSectionComponent,
    FAQSectionComponent,
    RouterLink,
    TranslateModule,
    LngRoutePipe,
  ]
})
export class HomePageComponent implements OnInit {
  private readonly renderer2 = inject(Renderer2);
  protected appStateService = inject(AppStateService);

  public readonly resumeBuildSteps = [
    {
      title: 'builder_steps.title1',
      desc: 'builder_steps.desc1',
      icon: 'sf-icon-pick-template'
    },
    {
      title: 'builder_steps.title2',
      desc: 'builder_steps.desc2',
      icon: 'sf-icon-layout-customize'
    },
    {
      title: 'builder_steps.title3',
      desc: 'builder_steps.desc3',
      icon: 'sf-icon-fill-blanks'
    },
    {
      title: 'builder_steps.title4',
      desc: 'builder_steps.desc4',
      icon: 'sf-icon-download'
    },
  ];

  ngOnInit() {
    this.appStateService.setPageSeo('home', this.renderer2);
  }

}

