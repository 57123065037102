<div class="tm-buttons-wr">

  @if (appStateService.isAuthenticated) {
    <nz-upload
      class="upload-cv-file"
      (click)="$event.stopPropagation()"
      nzAccept="application/pdf,.txt,.docx,.doc"
      [nzShowUploadList]="false"
      [nzBeforeUpload]="beforeUploadCurrent(template)">
      <button class="sf-btn-fifth small upload-cv">
        <i class="ph ph-upload-simple"></i>
        {{ 'resume_examples.upload_existing_CV' | translate }}
      </button>
    </nz-upload>
  } @else {
    @if (isReady) {
      <button class="sf-btn-fifth small upload-cv">
        <i class="ph ph-upload-simple"></i>
        {{ 'resume_examples.upload_existing_CV' | translate }}
      </button>
    }
  }

  <button class="sf-btn-primary small">
    <i class="ph ph-cursor-click"></i>
    {{ 'resume_examples.choose_template' | translate }}
  </button>
</div>

<ng-template #UploadCVCheckModalRef>
  <div class="pt-20">
    <div class="d-flex ai-c jc-c pt-6 fd-c">
      <nz-spin nzSimple [nzSize]="'large'"/>
    </div>
  </div>
</ng-template>
