import * as sections from '../sections';
import {Resource} from '@app/shared/interfaces';
import {TranslateModule} from "@ngx-translate/core";
import {Component, inject, OnInit, Renderer2} from '@angular/core';
import {AppStateService} from "@app/shared/services/app-state.service";

@Component({
  selector: 'sf-cv-templates-page',
  templateUrl: './cv-templates-page.component.html',
  styleUrls: ['./cv-templates-page.component.scss'],
  imports: [
    sections.FAQSectionComponent,
    sections.MoreThenSectionComponent,
    sections.WhyUseUsSectionComponent,
    sections.ResourcesSectionComponent,
    sections.TemplatesSectionComponent,
    sections.ResumeExamplesSectionComponent,
    TranslateModule
  ]
})
export class CvTemplatesPageComponent implements OnInit {
  private readonly renderer2 = inject(Renderer2);
  public readonly appStateService = inject(AppStateService);

  public readonly resources: Readonly<Resource[]> = [
    {
      title: 'templates_page.resume_resources.what_is_resume',
      content: 'templates_page.resume_resources.what_is_resume_desc',
      image: 'assets/img/illustrations/what-is-resume.svg',
      priority: true
    },
    {
      title: 'templates_page.resume_resources.how_long',
      content: 'templates_page.resume_resources.how_long_desc',
      image: 'assets/img/illustrations/CV-update.svg',
    },
    {
      title: 'templates_page.resume_resources.CV_resume',
      content: 'templates_page.resume_resources.CV_resume_desc',
      image: 'assets/img/illustrations/CV-vs-Resume.svg',
    },
    {
      title: 'templates_page.resume_resources.mistakes',
      content: 'templates_page.resume_resources.mistakes_desc',
      image: 'assets/img/illustrations/cover-letter-mistakes.svg',
    },
    {
      title: 'templates_page.resume_resources.multiple_versions',
      content: 'templates_page.resume_resources.multiple_versions_desc',
      image: 'assets/img/illustrations/resume-versions.svg',
    },
    {
      title: 'templates_page.resume_resources.address_gaps',
      content: 'templates_page.resume_resources.address_gaps_desc',
      image: 'assets/img/illustrations/gaps-in-history.svg'
    },
    {
      title: 'templates_page.resume_resources.fit_experience',
      content: 'templates_page.resume_resources.fit_experience_desc',
      image: 'assets/img/illustrations/fit-CV.svg',
    },
    {
      title: 'templates_page.resume_resources.update_CV',
      content: 'templates_page.resume_resources.update_CV_desc',
      image: 'assets/img/illustrations/cover-letter-need.svg'
    },
    {
      title: 'templates_page.resume_resources.how_long_should',
      content: 'templates_page.resume_resources.how_long_should_desc',
      image: 'assets/img/illustrations/CV-length.svg',
    },
  ];

  ngOnInit() {
    this.appStateService.setPageSeo('cv_templates', this.renderer2);
  }

}

