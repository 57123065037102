<div
  class="work-experience-part {{section.className}} section-container"
  [class.has-icon]="section.title.icon.state">

  <div class="w-e-t-w-t pb-4">
    <div
      class="work-exp-title-wrap"
      [ngStyle]="{
       backgroundColor: sectionName !== SectionNames.SIDEBAR_SECTION ? tmTheme.sectionMainTitleBgColor : 'transparent'
    }">

      <sf-title-action-banner
        [template]="template.settings"
        [page]="page"
        [pageIndex]="pageIndex"
        [section]="section"
        [sectionIndex]="sectionIndex"
        [sectionName]="sectionName"
        [elRef]="WorkExperienceListRef"
        [(selectedItem)]="selectedItem"
        (openRearrange$)="openRearrange$.emit()"
      />

      @if (section.title.icon.state) {
        <i class="{{section.title.icon.name}}"></i>
      }

      @if (section.title.state) {
        <h2
          contenteditable="true"
          [attr.placeholder]="section.title.pl | translate"
          [class.edit-mode]="selectedItem === section.title"
          [innerHTML]="section.title.text"
          (click)="onSelect(section.title)"
          (keydown.enter)="$event.preventDefault()"
          (blur)="onBlurSectionTitle($event, section)"
          (focus)="onFocusEL($event, section.title.text)"
          [sfContentEditableMaxLength]="appStateService.textMaxSizes.sectionMainTitle"
          [ngStyle]="{
            fontSize: appStateService.templateCurrentFontSize.sectionMainTitle + 'px',
            lineHeight: appStateService.templateCurrentFontSize.sectionMainTitleLineHeight + 'px',
            color: getH2Color()
        }"
        ></h2>
      }
    </div>
  </div>

  <div class="work-experience-list" #WorkExperienceListRef>
    @for (workExp of section.items; let sectionItemIndex = $index; let first = $first; let last = $last; track workExp) {

      <div class="work-experience-item_wrap">
        @if (template.templateId === 7 && sectionName) {
          <!-- DATE -->
          @if (workExp.date && sectionName !== SectionNames.SIDEBAR_SECTION) {
            <ng-container [ngTemplateOutlet]="DateRef" [ngTemplateOutletContext]="{workExp}"/>
          }
        }

        <div
          class="work-experience-item"
          [class.mb-0]="last"
          [class.edit-mode]="selectedItem === workExp"
          (click)="onSelect(workExp)">

          <div class="work-exp-item-child">
            @if (selectedItem === workExp) {
              <sf-tm-editor/>
            }

            <sf-actions-banner
              [pageIndex]="pageIndex"
              [template]="template.settings"

              [section]="section"
              [sectionIndex]="sectionIndex"
              [sectionName]="sectionName"

              [sectionItem]="workExp"
              [WorkExperienceListRef]="WorkExperienceListRef"
              [sectionItemIndex]="sectionItemIndex"
              [last]="last"
              [first]="first"

              [(selectedItem)]="selectedItem"
            />

            <div class="exp-title">
              <div class="title-wr">
                @if (workExp.title?.state) {
                  <h3
                    [class.mb-0]="!workExp.subTitle?.state && !workExp.desc?.state && !workExp.achievements?.state && !workExp.address?.state"
                    contenteditable="true"
                    [innerHTML]="workExp.title!.text"
                    [attr.placeholder]="workExp.title!.pl | translate"
                    (keydown.enter)="$event.preventDefault()"
                    (blur)="onBlurSectionItemTitle($event, workExp)"
                    (focus)="onFocusEL($event, workExp.title!.text)"
                    [sfContentEditableMaxLength]="appStateService.textMaxSizes.sectionTitle"
                    [ngStyle]="{
                        fontSize: appStateService.templateCurrentFontSize.sectionTitle + 'px',
                        lineHeight: appStateService.templateCurrentFontSize.sectionTitleLineHeight + 'px',
                        color: sectionName === SectionNames.SIDEBAR_SECTION ?
                          (selectedItem === workExp ? tmTheme.sidebar.sectionTitleFontEditModeColor : tmTheme.sidebar.sectionTitleFontColor) : tmTheme.sectionTitleFontColor,
                    }"
                  ></h3>
                }

                @if (sectionName === SectionNames.SIDEBAR_SECTION) {

                } @else {
                  @if (template.templateId === 7) {
                    <!-- Address -->
                    <ng-container [ngTemplateOutlet]="AddressRef" [ngTemplateOutletContext]="{workExp}"/>
                  } @else {
                    <!-- DATE -->
                    @if (workExp.date) {
                      <ng-container [ngTemplateOutlet]="DateRef" [ngTemplateOutletContext]="{workExp}"/>
                    }
                  }
                }

              </div>

              <div class="sb-title-wr">
                <!-- Subtitle -->
                @if (workExp.subTitle?.state) {
                  <h4
                    class="{{!workExp.desc?.state && !workExp.achievements?.state ? 'mb-0' : ''}}"
                    contenteditable="true"
                    [innerHTML]="workExp.subTitle?.text"
                    [attr.placeholder]="workExp.subTitle!.pl | translate"
                    (keydown.enter)="$event.preventDefault()"
                    (blur)="onBlurSectionItemSubTitle($event, workExp)"
                    (focus)="onFocusEL($event, workExp.subTitle!.text)"
                    [sfContentEditableMaxLength]="appStateService.textMaxSizes.sectionSubtitle"
                    [ngStyle]="{
                       fontSize: appStateService.templateCurrentFontSize.sectionSubtitle + 'px',
                       lineHeight: appStateService.templateCurrentFontSize.sectionSubtitleLineHeight + 'px',
                       color: sectionName === SectionNames.SIDEBAR_SECTION ?
                            (selectedItem === workExp ?  tmTheme.sidebar.sectionSubtitleEditModeFontColor :  tmTheme.sidebar.sectionSubtitleFontColor)
                            : tmTheme.sectionSubtitleFontColor
                       }"
                  ></h4>
                }

                @if (sectionName === SectionNames.SIDEBAR_SECTION) {
                  <!-- DATE -->
                  @if (workExp.date) {
                    <ng-container [ngTemplateOutlet]="DateRef" [ngTemplateOutletContext]="{workExp}"/>
                  }

                  <!-- Address -->
                  <ng-container [ngTemplateOutlet]="AddressRef" [ngTemplateOutletContext]="{workExp}"/>
                } @else {
                  <!-- Address -->
                  @if (template.templateId !== 7) {
                    <ng-container [ngTemplateOutlet]="AddressRef" [ngTemplateOutletContext]="{workExp}"/>
                  }
                }
              </div>
            </div>

            <!-- Description -->
            @if (workExp.desc?.state) {
              <p
                [ngStyle]="{
              fontSize: appStateService.templateCurrentFontSize.sectionDesc + 'px',
              lineHeight: appStateService.templateCurrentFontSize.sectionDescLineHeight + 'px',
          }"
                (mouseup)="handleMouseUp(workExp.desc)"
                (sfCtrlA)="handleCtrlA(template.settings.summary.desc)"
                class="exp-desc {{!workExp.achievements?.state ? 'mb-0' : ''}}"
                contenteditable="true"
                [innerHTML]="workExp.desc!.text"
                [attr.placeholder]="workExp.desc!.pl | translate"
                (keydown.enter)="onEnterDesc($event)"
                (keydown.backspace)="onBackspaceDesc($event)"
                (keydown.delete)="onBackspaceDesc($event)"
                (blur)="onBlurSectionItemDesc($event, workExp.desc)"
                (focus)="onFocusEL($event, workExp.desc!.text)"
                [sfContentEditableMaxLength]="appStateService.textMaxSizes.sectionDesc"
                sfClickableLinks
              ></p>
            }

            <!-- achievements -->
            @if (workExp.achievements?.state) {
              <div class="resp-area">
                <div
                  class="achievements"
                  contenteditable="true"
                  [ngStyle]="{
                    fontSize: appStateService.templateCurrentFontSize.sectionAchievement + 'px',
                    lineHeight: appStateService.templateCurrentFontSize.sectionAchievementLineHeight + 'px',
                  }"
                  [innerHTML]="workExp.achievements!.title!.text"
                  [attr.placeholder]="workExp.achievements!.title!.pl | translate"
                  (keydown.enter)="$event.preventDefault()"
                  (focus)="onFocusEL($event, workExp.achievements!.title!.text)"
                  (blur)="onBlurAchievements($event, workExp)"
                  [sfContentEditableMaxLength]="appStateService.textMaxSizes.achievementTitle"
                ></div>

                <ul
                  #AchievementsRef
                  class="ach-drag-boundary"
                  [style.gridTemplateColumns]="'repeat(' + workExp.achievements?.column?.cols + ', 1fr)'"
                  [class.cols-2]="workExp.achievements?.column?.cols === 2"
                  [class.cols-3]="workExp.achievements?.column?.cols === 3">
                  @for (achievement of workExp.achievements!.items; let achIndex = $index; track achIndex) {
                    <li
                      (mouseup)="handleMouseUp(achievement)"
                      (sfCtrlA)="handleCtrlA(template.settings.summary.desc)"
                      contenteditable="true"
                      [ngStyle]="{
                         fontSize: appStateService.templateCurrentFontSize.sectionAchList + 'px',
                         lineHeight: appStateService.templateCurrentFontSize.sectionAchListLineHeight + 'px',
                      }"
                      [innerHTML]="achievement.text"
                      [attr.placeholder]="(workExp.achievements?.achItemPl || '') | translate"
                      (focus)="onFocusEL($event, achievement.text)"
                      (blur)="onBlurAchievement($event, achievement)"
                      (keydown.enter)="onAddAchievement($event, workExp.achievements, achIndex, AchievementsRef)"
                      (keydown.backspace)="onRemoveAchievement($event, workExp.achievements, achIndex, AchievementsRef)"
                      (keydown.arrowUp)="onAchUpArrow($event, achIndex, AchievementsRef)"
                      (keydown.delete)="onDeleteAchievement($event, workExp.achievements, achIndex, AchievementsRef)"
                      (keydown.arrowDown)="onAchDownArrow($event, achIndex, AchievementsRef)"
                      [sfContentEditableMaxLength]="appStateService.textMaxSizes.achievement"
                      sfClickableLinks
                    ></li>
                  }
                </ul>
              </div>
            }
          </div>
        </div>
      </div>
    }

  </div>
</div>

@if (!lastSection) {
  <div class="sf-line"></div>
}

<ng-template #AddressRef let-workExp="workExp">
  @if (workExp.address?.state) {
    <div
      (mouseup)="handleMouseUp(workExp.address)"
      class="rs tm-sec-address {{!workExp.desc?.state && !workExp.achievements?.state ? 'mb-0' : ''}}"
      contenteditable="true"
      [innerHTML]="workExp.address?.text"
      [attr.placeholder]="workExp.address!.pl | translate"
      (keydown.enter)="$event.preventDefault()"
      (blur)="onBlurSectionItemDesc($event, workExp.address)"
      (focus)="onFocusEL($event, workExp.address!.text)"
      [sfContentEditableMaxLength]="appStateService.textMaxSizes.address"
      [ngStyle]="{
          fontSize: appStateService.templateCurrentFontSize.sectionDateAndAddress + 'px',
          lineHeight: appStateService.templateCurrentFontSize.sectionDateAndAddressLineHeight + 'px',
          color: sectionName === SectionNames.SIDEBAR_SECTION ? tmTheme.sidebar.countryColor : tmTheme.countryColor
      }"
    ></div>
  }
</ng-template>

<ng-template #DateRef let-workExp="workExp">
  <div
    class="tm-date-container"
    (click)="onSelectDate($event, workExp)"
    (sfClickOutside)="onClickOutsideDate()"
    [ngStyle]="{
               fontSize: appStateService.templateCurrentFontSize.sectionDateAndAddress + 'px',
               lineHeight: appStateService.templateCurrentFontSize.sectionDateAndAddressLineHeight + 'px',
               color: tmTheme.sidebar.dateColor,
     }">
    @if (workExp.date!.state) {
      <div class="rs mb-2" [innerHTML]="getDateString(workExp)"></div>
    }

    @if (selectedDate === workExp.date) {
      <div class="tm-date-wrapper">
        <sf-tm-date-picker [(period)]="workExp.date" (periodChange)="saveChanges()"/>
      </div>
    }
  </div>
</ng-template>

