<div
  class="template-main-container
         {{'template-size-' + appStateService.templateCurrentFontSize.className}}
         tm-type-{{template.settings.type}}
         template-id-{{template.templateId}}
         sidebar-position-{{template.settings.sidebarPosition}}
         theme-id-{{tmTheme.id}}
         template-top-part-type-{{template.settings.templateTopPartType}}
         {{forPreview ? 'tm-preview-mode' : ''}}"
  [style.--tm-link-color]="tmTheme.linkColor"
  [style.--tm-sidebar-link-color]="tmTheme.sidebar.linkColor"
  [style.--before-after-color]="tmTheme.demoColor2"
  [class.for-preview]="forPreview"
  [ngStyle]="{
      pointerEvents:  forPreview ? 'none' : 'initial',
      fontFamily: template.settings.settings.font.type.fontFamily
  }"
  (sfClickOutside)="onClickOutSide()">

  @if (window.innerWidth > 1150) {
    @if (!forPreview && !forPrint) {
      <sf-tm-resize [template]="template.settings"/>
    }
  }

  @for (page of template.settings.pages.items; let pageIndex = $index; let firstPage = $first; let lastPage = $last; track pageIndex) {
    @if (firstPageOnly) {
      <!-- To render only fist page of cv on dashboard page -->
      @if (firstPage) {
        <ng-container
          [ngTemplateOutlet]="PageItemRef"
          [ngTemplateOutletContext]="{page, pageIndex, firstPage, lastPage}"
        />
      }
    } @else {
      <ng-container
        [ngTemplateOutlet]="PageItemRef"
        [ngTemplateOutletContext]="{page, pageIndex, firstPage, lastPage}"
      />
    }
  }
</div>

<ng-template #PageItemRef let-page="page" let-pageIndex="pageIndex" let-firstPage="firstPage" let-lastPage="lastPage">
  @switch (template.settings.type) {
    @case (TemplateTypes.SIDEBAR) {
      <div class="{{TmClassnames.TEMPLATE_PAGE_PARENT}} page-{{pageIndex + 1}}">

        @if (firstPage && [5].includes(template.templateId)) {
          <sf-tm-5-sidebar-top-part
            [template]="template.settings"
            [tmTheme]="tmTheme"
            [forPrint]="forPrint"
            [selectedItem]="selectedItem"
            (selectedItemChange)="onSelect($event)"
          />
        }

        @if (firstPage && [7].includes(template.templateId)) {
          <!--<sf-tm-top-part
            [tmTheme]="tmTheme"
            [forPrint]="forPrint"
            [template]="template.settings"
            [selectedItem]="selectedItem"
            (selectedItemChange)="onSelect($event)"
          />-->


          <sf-tm-top-part
            [tmTheme]="tmTheme"
            [forPrint]="forPrint"
            [template]="template.settings"
            [(selectedItem)]="selectedItem"
            (settingsSelected$)="openSocialInfoSettings()"
          />
        }

        <div class="template-page">
          @if (selectedItem) {
            <div [@fadeInOut] class="template-overlay" (click)="onOverlayClick()"></div>
          }

          <ng-container
            [ngTemplateOutlet]="LeftSideTemplatePageRef"
            [ngTemplateOutletContext]="{page, pageIndex, firstPage}"
          />

          <ng-container
            [ngTemplateOutlet]="RightSideTemplatePageRef"
            [ngTemplateOutletContext]="{page, pageIndex, firstPage}"
          />

          <ng-container
            [ngTemplateOutlet]="BottomPartRef"
            [ngTemplateOutletContext]="{pageIndex}"
          />
        </div>
      </div>
    }
    @case (TemplateTypes.ONE_ROW) {
      <div class="{{TmClassnames.TEMPLATE_PAGE_PARENT}} page-{{pageIndex + 1}}">
        <div class="template-page">
          <div *ngIf="selectedItem" [@fadeInOut] class="template-overlay" (click)="onOverlayClick()"></div>

          <ng-container
            [ngTemplateOutlet]="OneRowTemplatePageRef"
            [ngTemplateOutletContext]="{page, pageIndex, firstPage}"
          />

          <ng-container
            [ngTemplateOutlet]="BottomPartRef"
            [ngTemplateOutletContext]="{pageIndex}"
          />
        </div>
      </div>
    }
    @case (TemplateTypes.TWO_ROWS) {
      <div class="{{TmClassnames.TEMPLATE_PAGE_PARENT}} page-{{pageIndex + 1}}">
        <div class="template-page">
          <div *ngIf="selectedItem" [@fadeInOut] class="template-overlay" (click)="onOverlayClick()"></div>

          <ng-container
            [ngTemplateOutlet]="TwoRowTemplatePageRef"
            [ngTemplateOutletContext]="{page, pageIndex, firstPage}"
          />

          <ng-container
            [ngTemplateOutlet]="BottomPartRef"
            [ngTemplateOutletContext]="{pageIndex}"
          />
        </div>
      </div>
    }
  }

  <div class="pages-space" *ngIf="!lastPage && !forPrint">
    <div class="page-number">
      <div>---</div>
    </div>
  </div>
</ng-template>

<!--------------------------------------------------------------------------------------------------------------------->


<!-- Sidebar Template Start -->

<ng-template #LeftSideTemplatePageRef let-page="page" let-pageIndex="pageIndex" let-firstPage="firstPage">
  @if ((page | pageType); as page) {
    <div
      class="left-side {{firstPage ? '' : 'pt-34'}}"
      [style.width.px]="template.settings.settings.sidebar.with"
      [ngStyle]="{
           fontFamily: template.settings.settings.font.type.fontFamily,
           backgroundColor: tmTheme.sidebar.bgColor,
           color: tmTheme.sidebar.fontColor,
           width: template.settings.settings.sidebar.with,
           borderTop: '1px solid ' + tmTheme.sidebar.border.borderTopColor,
           borderBottom: '1px solid ' + tmTheme.sidebar.border.borderBottomColor,

           borderLeft: '1px solid ' +  (template.settings.sidebarPosition === 'right' ? tmTheme.sidebar.border.borderLeftColor : tmTheme.sidebar.border.borderRightColor),
           borderRight: '1px solid ' + (template.settings.sidebarPosition === 'right' ? tmTheme.sidebar.border.borderRightColor : tmTheme.sidebar.border.borderLeftColor),
        }">

      @if (firstPage) {
        <div class="sidebar-reverse-wr" [nz-tooltip]="'tooltips.swipe_sidebar' | translate" id="introjs_swipe-sidebar">
          <i class="ph ph-swap" (click)="onSidebarSwipe()"></i>
        </div>
      }

      @if (template.settings.img.state && firstPage) {
        @if (![4, 5, 7].includes(template.templateId)) {

          @if (!forPrint || (forPrint && (appStateService.user?.image || template.settings.img.src))) {
            <sf-template-img [stroke]="tmTheme.sidebar.emptyImgColor" [template]="template.settings"/>
          }

        }
      }

      <div
        class="left-side-content"
        [class.pt-34]="firstPage &&
        (
          (!template.settings.img.state || [4].includes(template.templateId)) ||
          (forPrint && !(appStateService.user?.image || template.settings.img.src))
        )">

        @if (firstPage && template.settings.socialInfo.onSidebar) {
          <sf-social-info-sidebar
            [template]="template.settings"
            [tmTheme]="tmTheme"
            [(selectedItem)]="selectedItem"
            (openSocialInfoSettings$)="openSocialInfoSettings()"
          />
        }

        <ng-container
          [ngTemplateOutlet]="EmptySectionRef"
          [ngTemplateOutletContext]="{firstPage}"
          *ngIf="!page.sidebarSection.items.length"
        />

        <ng-container
          *ngFor="let section of page.sidebarSection.items; let sectionIndex = index; let lastSection = last">
          <ng-container
            [ngTemplateOutlet]="SectionRef"
            [ngTemplateOutletContext]="{page, pageIndex, section, sectionIndex, lastSection, sectionName: SectionNames.SIDEBAR_SECTION}"
          />
        </ng-container>
      </div>
    </div>
  }
</ng-template>

<ng-template #RightSideTemplatePageRef let-page="page" let-pageIndex="pageIndex" let-firstPage="firstPage">
  <div class="right-side" *ngIf="(page | pageType) as page">

    @if (firstPage && ![5, 7].includes(template.templateId)) {
      <sf-tm-sidebar-top-part
        [template]="template.settings"
        [tmTheme]="tmTheme"
        [forPrint]="forPrint"
        [(selectedItem)]="selectedItem"
      />
    }

    <div class="right-side-body">
      @if (firstPage && [5].includes(template.templateId) && !template.settings.socialInfo.onSidebar) {
        <sf-tm-social-info [template]="template.settings" [tmTheme]="tmTheme" [type]="3"/>
      }

      @for (section of page.sidebarRightSection.items; let sectionIndex = $index; let lastSection = $last; track section) {
        <ng-container
          [ngTemplateOutlet]="SectionRef"
          [ngTemplateOutletContext]="{page, pageIndex, section, sectionIndex, lastSection, sectionName: SectionNames.SIDEBAR_RIGHT_SECTION}"
        />
      }
    </div>

    <ng-container
      [ngTemplateOutlet]="EmptySectionRef"
      [ngTemplateOutletContext]="{firstPage}"
      *ngIf="!page.sidebarRightSection.items.length"
    />
  </div>
</ng-template>


<!-- Sidebar Template End -->


<!--------------------------------------------------------------------------------------------------------------------->


<!-- One Row Template Start -->

<ng-template #OneRowTemplatePageRef let-page="page" let-pageIndex="pageIndex" let-firstPage="firstPage">
  <div class="one-row-tm right-side {{firstPage ? '' : 'pt-50'}}" *ngIf="(page | pageType) as page">

    <sf-tm-top-part
      *ngIf="firstPage"
      [tmTheme]="tmTheme"
      [forPrint]="forPrint"
      [template]="template.settings"
      [(selectedItem)]="selectedItem"
      (settingsSelected$)="openSocialInfoSettings()"
    />

    <div class="one-row-body">

      <ng-container
        [ngTemplateOutlet]="EmptySectionRef"
        [ngTemplateOutletContext]="{firstPage}"
        *ngIf="!page.section.items.length"
      />

      <ng-container *ngFor="let section of page.section.items; let sectionIndex = index; let lastSection = last">
        <ng-container
          [ngTemplateOutlet]="SectionRef"
          [ngTemplateOutletContext]="{page, pageIndex, section, sectionIndex, lastSection, sectionName: SectionNames.SECTION}"
        />
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- One Row Template End -->


<!--------------------------------------------------------------------------------------------------------------------->


<!-- Two Row Template Start -->

<ng-template #TwoRowTemplatePageRef let-page="page" let-pageIndex="pageIndex" let-firstPage="firstPage">
  <div class="two-row-tm right-side {{firstPage ? '' : 'pt-40'}}" *ngIf="(page | pageType) as page">

    <sf-tm-top-part
      *ngIf="firstPage"
      [tmTheme]="tmTheme"
      [template]="template.settings"
      [forPrint]="forPrint"
      [(selectedItem)]="selectedItem"
      (settingsSelected$)="openSocialInfoSettings()"
    />

    <div class="two-row-body">
      <div class="two-r-left" [style.width.px]="template.settings.settings.twoRows.with - 16">
        <ng-container
          [ngTemplateOutlet]="EmptySectionRef"
          [ngTemplateOutletContext]="{firstPage}"
          *ngIf="!page.twoRowLeftSideSection.items.length"
        />

        <ng-container
          *ngFor="let section of page.twoRowLeftSideSection.items; let sectionIndex = index; let lastSection = last"
          [ngTemplateOutlet]="SectionRef"
          [ngTemplateOutletContext]="{page, pageIndex, section, sectionIndex, lastSection, sectionName: SectionNames.TWO_ROW_LEFT_SIDE_SECTION}"
        />
      </div>

      <div class="two-r-right" [style.width.px]="852 - (template.settings.settings.twoRows.with - 16)">
        <ng-container
          [ngTemplateOutlet]="EmptySectionRef"
          [ngTemplateOutletContext]="{firstPage}"
          *ngIf="!page.twoRowRightSideSection.items.length"
        />

        <ng-container
          *ngFor="let section of page.twoRowRightSideSection.items; let sectionIndex = index; let lastSection = last"
          [ngTemplateOutlet]="SectionRef"
          [ngTemplateOutletContext]="{page, pageIndex, section, sectionIndex, lastSection, sectionName: SectionNames.TWO_ROW_RIGHT_SIDE_SECTION}"
        />
      </div>
    </div>

  </div>
</ng-template>

<!-- Two Row Template End -->


<!--------------------------------------------------------------------------------------------------------------------->


<!-- Template Sections Start -->

<ng-template
  #SectionRef
  let-page="page"
  let-pageIndex="pageIndex"
  let-section="section"
  let-sectionIndex="sectionIndex"
  let-lastSection="lastSection"
  let-sectionName="sectionName">
  <ng-container *ngIf="(section | sectionType) as section">
    <sf-work-exp-section-list
      *ngIf="appStateService.workExpList.includes(section.type)"
      [template]="template"
      [tmTheme]="tmTheme"

      [page]="page"
      [pageIndex]="pageIndex"

      [section]="section"
      [sectionName]="sectionName"
      [sectionIndex]="sectionIndex"
      [lastSection]="lastSection"

      [(selectedItem)]="selectedItem"
      (openRearrange$)="openRearrange$.emit()"
    />

    <sf-list-section-list
      *ngIf="appStateService.listSectionsList.includes(section.type)"
      [template]="template.settings"
      [tmTheme]="tmTheme"

      [page]="page"
      [pageIndex]="pageIndex"

      [section]="section"
      [sectionName]="sectionName"
      [sectionIndex]="sectionIndex"
      [lastSection]="lastSection"

      [(selectedItem)]="selectedItem"
      [(selectedListItem)]="selectedListItem"
      (openRearrange$)="openRearrange$.emit()"
    />

    <!--<ng-container [ngSwitch]="section.type">

      <ng-container *ngSwitchCase="TemplateSectionTypes.REFERENCES">
        <sf-tm-references-section
          [template]="template"
          [tmTheme]="tmTheme"

          [page]="page"
          [pageIndex]="pageIndex"

          [section]="section"
          [lastSection]="lastSection"
          [sectionIndex]="sectionIndex"
          [sectionName]="sectionName"
          [(selectedItem)]="selectedItem"
          (openRearrange$)="openRearrange$.emit()"
        />
      </ng-container>

    </ng-container>-->

  </ng-container>
</ng-template>

<!-- Template Sections End -->


<!--------------------------------------------------------------------------------------------------------------------->


<ng-template #DrawerTitleRef>
  <div class="dashboard-sidebar-title">
    Social Info Settings
    <i nz-icon nzType="close" nzTheme="outline" (click)="onCloseSidebar()"></i>
  </div>
</ng-template>

<ng-template #EmptySectionRef let-firstPage="firstPage">
  @if (!forPreview && !forPrint) {
    <sf-empty-section
      [template]="template.settings"
      [tmTheme]="tmTheme"
      [firstPage]="firstPage"
      (select$)="onAddSection()"/>
  }
</ng-template>

<ng-template #BottomPartRef let-pageIndex="pageIndex">
  <div class="pagination" *ngIf="template.settings.pages.items.length > 1">{{ pageIndex + 1 }}</div>

  <sf-branding [forPrint]="forPrint" [tmTheme]="tmTheme" [template]="template.settings"/>
</ng-template>
