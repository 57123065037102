import {Component, Input} from '@angular/core';

@Component({
    selector: 'sf-project-social-icons',
    templateUrl: './project-social-icons.component.html',
    styleUrl: './project-social-icons.component.scss',
    imports: []
})
export class ProjectSocialIconsComponent {
  @Input() public className = '';
}
