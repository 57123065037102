import {cloneObject} from "@app/shared/helpers";
import {
  TemplateInfographics,
  TemplateSectionTypes,
  TemplateSettingsShapes,
  TemplateTypes,
  TmFontSizesTypes
} from "@app/shared/enums";
import {
  AllCoverLetterDefaults,
  AllTemplateDefaults,
  TemplateConfigSettings,
  TemplateSection
} from "@app/shared/interfaces";
import {
  allSectionsDefaults,
  allSectionsDefaults2,
  allSectionsItemsDefaults,
  socialInfo,
  templateBranding,
  templateImg,
  templatePart
} from "@app/shared/constants/template-defaults";

import {coverLetter1} from "@app/shared/constants/cover-letter/cover-letter-1";
import {coverLetter2} from "@app/shared/constants/cover-letter/cover-letter-2";
import {coverLetter3} from "@app/shared/constants/cover-letter/cover-letter-3";
import {coverLetter4} from "@app/shared/constants/cover-letter/cover-letter-4";
import {coverLetter5} from "@app/shared/constants/cover-letter/cover-letter-5";
import {coverLetter6} from "@app/shared/constants/cover-letter/cover-letter-6";
import {coverLetter7} from "@app/shared/constants/cover-letter/cover-letter-7";

const pureTemplateLanguages: TemplateSection = {
  type: TemplateSectionTypes.LANGUAGES,
  title: {
    icon: {
      state: false,
      name: ''
    },
    text: 'LANGUAGES',
    pl: 'tm.pl.LANGUAGES',
    state: true
  },
  className: 'lng',
  hasIcon: false,
  pl: 'tm.pl.Language',
  infographics: {
    state: false,
    type: TemplateInfographics.ROUND,
  },
  items: [
    {
      icon: 'ph ph-globe-hemisphere-east',
      text: '',
      textValue: 'Beginner',
      skillCount: 1
    }
  ],
  settings: {
    hasTextOptions: true,
    selectedShape: {
      type: TemplateSettingsShapes.TEXT_OPTIONS,
      hasIcon: false,
      chipValue: '',
      infographicValue: '',
    },
    listTypeOnSidebar: 'list'
  },
  sectionInfo: {
    name: 'tm.sn.languages',
    icon: ''
  },
};

const pureTemplateCauses: TemplateSection = {
  type: TemplateSectionTypes.CAUSES,
  title: {
    icon: {
      state: false,
      name: ''
    },
    text: 'CAUSES',
    pl: 'tm.pl.CAUSES',
    state: true
  },
  className: 'causes',
  hasIcon: false,
  pl: 'tm.pl.Cause',
  infographics: {
    state: false,
    type: TemplateInfographics.ROUND,
  },
  items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.CAUSES])],
  settings: {
    selectedShape: {
      type: TemplateSettingsShapes.TEXT,
      hasIcon: false,
      chipValue: '',
      infographicValue: '',
    },
    listTypeOnSidebar: 'wrap'
  },
  sectionInfo: {
    name: 'tm.sn.causes',
    icon: ''
  }
};

const pureTemplateAreasOfExp: TemplateSection = {
  type: TemplateSectionTypes.AREAS_OF_EXPERTISE,
  title: {
    icon: {
      state: false,
      name: ''
    },
    text: 'AREAS OF EXPERTISE',
    pl: 'tm.pl.AREAS_OF_EXPERTISE',
    state: true
  },
  className: 'areas-of-expertise',
  hasIcon: false,
  pl: 'tm.pl.Email_Marketing',
  infographics: {
    state: false,
    type: TemplateInfographics.ROUND,
  },
  items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.AREAS_OF_EXPERTISE])],
  settings: {
    selectedShape: {
      type: TemplateSettingsShapes.TEXT,
      hasIcon: false,
      chipValue: '',
      infographicValue: '',
    },
    listTypeOnSidebar: 'wrap'
  },
  sectionInfo: {
    name: 'tm.sn.areas_expertise',
    icon: ''
  },
};

const pureTemplateInterests: TemplateSection = {
  type: TemplateSectionTypes.INTERESTS,
  title: {
    icon: {
      state: false,
      name: ''
    },
    text: 'INTERESTS',
    pl: 'tm.pl.INTERESTS',
    state: true
  },
  className: 'interests',
  hasIcon: false,
  pl: 'tm.pl.Sport',
  infographics: {
    state: false,
    type: TemplateInfographics.ROUND,
  },
  items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.INTERESTS])],
  settings: {
    selectedShape: {
      type: TemplateSettingsShapes.TEXT,
      hasIcon: false,
      chipValue: '',
      infographicValue: '',
    },
    listTypeOnSidebar: 'wrap'
  },
  sectionInfo: {
    name: 'tm.sn.interests',
    icon: ''
  },
};

export const getAllSectionsExpect = (sectionNames: TemplateSectionTypes[]) => {
  return Object.values(TemplateSectionTypes)
    .filter((val) => !sectionNames.includes(val))
    .map((name) => cloneObject(allSectionsDefaults[name]));
}

export const getSections = (sectionNames: TemplateSectionTypes[]) => {
  return sectionNames.map((name) => cloneObject(allSectionsDefaults[name]));
}

/* ------------------------------------------------------------------------------------------------------------------ */

export const getAllSectionsExpect2 = (sectionNames: TemplateSectionTypes[]) => {
  return Object.values(TemplateSectionTypes)
    .filter((val) => !sectionNames.includes(val))
    .map((name) => cloneObject(allSectionsDefaults2[name]));
}

export const getSections2 = (sectionNames: TemplateSectionTypes[]) => {
  return sectionNames.map((name) => cloneObject(allSectionsDefaults2[name]));
}

const setSettings: TemplateConfigSettings = {
  font: {
    type: {name: 'Poppins', fontFamily: 'Poppins', paid: false},
    size: {
      name: TmFontSizesTypes.MEDIUM
    },
  },
  theme: {
    colorId: 1
  },
  sidebar: {
    with: 306,
    maxWidth: 390,
    minWidth: 250,
  },
  twoRows: {
    with: 442,
    maxWidth: 550,
    minWidth: 316,
  }
};

export const allTemplateDefaults: AllTemplateDefaults = {
  template1: {
    ...cloneObject(templatePart),
    summary: {
      state: true,
      title: {
        text: 'SUMMARY',
        pl: 'tm.pl.SUMMARY',
        state: true
      },
      desc: {
        text: '',
        pl: 'tm.pl.Write_brief_overview',
        hasEditor: true
      },
    },
    type: TemplateTypes.ONE_ROW,
    sidebarPosition: 'left',
    templateTopPartType: 1,
    templateSidebarTopPart: 1,
    pages: {
      items: [
        {
          section: {
            items: [
              ...getSections([
                TemplateSectionTypes.WORK_EXPERIENCE,
                TemplateSectionTypes.EDUCATION,
                TemplateSectionTypes.SKILLS,
                TemplateSectionTypes.LANGUAGES,
                TemplateSectionTypes.INTERESTS,
              ])
            ]
          },
          sidebarSection: {
            items: []
          },
          sidebarRightSection: {
            items: []
          },
          twoRowRightSideSection: {
            items: []
          },
          twoRowLeftSideSection: {
            items: []
          },
        },
      ]
    },
    allSections: {
      items: [
        ...getAllSectionsExpect([
          TemplateSectionTypes.WORK_EXPERIENCE,
          TemplateSectionTypes.EDUCATION,
          TemplateSectionTypes.SKILLS,
          TemplateSectionTypes.LANGUAGES,
          TemplateSectionTypes.INTERESTS,
        ])
      ]
    },
    premium: false,
    settings: cloneObject(setSettings)
  },
  template2: {
    ...cloneObject(templatePart),
    summary: {
      state: true,
      title: {
        text: 'SUMMARY',
        pl: 'tm.pl.SUMMARY',
        state: true
      },
      desc: {
        text: '',
        pl: 'tm.pl.Write_brief_overview',
        hasEditor: true
      },
      icon: {
        state: true,
        name: 'icon-sf-summary'
      },
    },
    type: TemplateTypes.SIDEBAR,
    sidebarPosition: 'left',
    templateTopPartType: 2,
    templateSidebarTopPart: 1,
    pages: {
      items: [
        {
          section: {
            items: []
          },
          sidebarSection: {
            items: [
              ...getSections2([
                TemplateSectionTypes.SKILLS,
                TemplateSectionTypes.LANGUAGES,
                TemplateSectionTypes.INTERESTS,
              ])
            ]
          },
          sidebarRightSection: {
            items: [
              ...getSections2([
                TemplateSectionTypes.WORK_EXPERIENCE,
                TemplateSectionTypes.EDUCATION,
              ])
            ]
          },
          twoRowRightSideSection: {
            items: []
          },
          twoRowLeftSideSection: {
            items: []
          },
        },
      ]
    },
    allSections: {
      items: [
        ...getAllSectionsExpect2([
          TemplateSectionTypes.WORK_EXPERIENCE,
          TemplateSectionTypes.EDUCATION,
          TemplateSectionTypes.SKILLS,
          TemplateSectionTypes.LANGUAGES,
          TemplateSectionTypes.INTERESTS,
        ])
      ]
    },
    premium: true,
    settings: cloneObject(setSettings)
  },
  template3: {
    version: '1.0.0',
    title: {
      text: '',
      pl: 'tm.pl.Name_Surname',
      state: true,
    },
    subTitle: {
      text: '',
      pl: 'tm.pl.Your_Profession',
      state: true
    },
    summary: {
      state: true,
      title: {
        text: 'SUMMARY',
        pl: 'tm.pl.SUMMARY',
        state: false
      },
      desc: {
        text: '',
        pl: 'tm.pl.Write_brief_overview',
        hasEditor: true
      },
    },
    img: cloneObject(templateImg),
    socialInfo: cloneObject(socialInfo),
    branding: cloneObject(templateBranding),
    type: TemplateTypes.SIDEBAR,
    sidebarPosition: 'right',
    templateTopPartType: 5,
    templateSidebarTopPart: 1,
    pages: {
      items: [
        {
          section: {
            items: []
          },
          sidebarSection: {
            items: [
              ...getSections([
                TemplateSectionTypes.SKILLS,
                TemplateSectionTypes.LANGUAGES,
                TemplateSectionTypes.INTERESTS,
              ])
            ]
          },
          sidebarRightSection: {
            items: [
              ...getSections([
                TemplateSectionTypes.WORK_EXPERIENCE,
                TemplateSectionTypes.EDUCATION,
              ])
            ]
          },
          twoRowRightSideSection: {
            items: []
          },
          twoRowLeftSideSection: {
            items: []
          },
        },
      ]
    },
    allSections: {
      items: [
        ...getAllSectionsExpect([
          TemplateSectionTypes.WORK_EXPERIENCE,
          TemplateSectionTypes.EDUCATION,
          TemplateSectionTypes.SKILLS,
          TemplateSectionTypes.LANGUAGES,
          TemplateSectionTypes.INTERESTS,
        ])
      ]
    },
    premium: true,
    settings: cloneObject(setSettings)
  },
  template4: {
    version: '1.0.0',
    title: {
      text: '',
      pl: 'tm.pl.Name_Surname',
      state: true,
    },
    subTitle: {
      text: '',
      pl: 'tm.pl.Your_Profession',
      state: true
    },
    summary: {
      state: true,
      title: {
        text: 'SUMMARY',
        pl: 'tm.pl.SUMMARY',
        state: true
      },
      desc: {
        text: '',
        pl: 'tm.pl.Write_brief_overview',
        hasEditor: true
      },
    },
    img: cloneObject(templateImg),
    socialInfo: {...cloneObject(socialInfo), onSidebar: true, title: {...socialInfo.title, state: false}},
    branding: cloneObject(templateBranding),
    type: TemplateTypes.TWO_ROWS,
    sidebarPosition: 'right',
    templateTopPartType: 7,
    templateSidebarTopPart: 1,
    pages: {
      items: [
        {
          section: {
            items: []
          },
          sidebarSection: {
            items: []
          },
          sidebarRightSection: {
            items: []
          },
          twoRowRightSideSection: {
            items: [
              ...getSections([
                TemplateSectionTypes.WORK_EXPERIENCE,
                TemplateSectionTypes.SKILLS,
              ])
            ]
          },
          twoRowLeftSideSection: {
            items: [
              ...getSections([
                TemplateSectionTypes.EDUCATION,
                TemplateSectionTypes.LANGUAGES,
                TemplateSectionTypes.INTERESTS,
              ])
            ]
          },
        },
      ]
    },
    allSections: {
      items: [
        ...getAllSectionsExpect([
          TemplateSectionTypes.WORK_EXPERIENCE,
          TemplateSectionTypes.EDUCATION,
          TemplateSectionTypes.SKILLS,
          TemplateSectionTypes.LANGUAGES,
          TemplateSectionTypes.INTERESTS,
        ])
      ]
    },
    premium: true,
    settings: cloneObject(setSettings)
  },
  template5: {
    ...cloneObject(templatePart),
    summary: {
      state: true,
      title: {
        text: 'SUMMARY',
        pl: 'tm.pl.SUMMARY',
        state: false
      },
      desc: {
        text: '',
        pl: 'tm.pl.Write_brief_overview',
        hasEditor: true
      },
    },
    type: TemplateTypes.TWO_ROWS,
    sidebarPosition: 'right',
    templateTopPartType: 3,
    templateSidebarTopPart: 1,
    pages: {
      items: [
        {
          section: {
            items: []
          },
          sidebarSection: {
            items: []
          },
          sidebarRightSection: {
            items: []
          },
          twoRowRightSideSection: {
            items: [
              ...getSections([
                TemplateSectionTypes.WORK_EXPERIENCE,
                TemplateSectionTypes.SKILLS,
              ])
            ]
          },
          twoRowLeftSideSection: {
            items: [
              ...getSections([
                TemplateSectionTypes.EDUCATION,
                TemplateSectionTypes.LANGUAGES,
                TemplateSectionTypes.INTERESTS,
              ])
            ]
          },
        },
      ]
    },
    allSections: {
      items: [
        ...getAllSectionsExpect([
          TemplateSectionTypes.WORK_EXPERIENCE,
          TemplateSectionTypes.EDUCATION,
          TemplateSectionTypes.SKILLS,
          TemplateSectionTypes.LANGUAGES,
          TemplateSectionTypes.INTERESTS,
        ])
      ]
    },
    premium: true,
    settings: cloneObject(setSettings)
  },
  template6: {
    version: '1.0.0',
    title: {
      text: '',
      pl: 'tm.pl.Name_Surname',
      state: true,
    },
    subTitle: {
      text: '',
      pl: 'tm.pl.Your_Profession',
      state: true
    },
    img: {...cloneObject(templateImg), state: false},
    socialInfo: {
      ...cloneObject(socialInfo),
      title: {
        icon: {
          state: false,
          name: ''
        },
        text: 'CONTACTS',
        pl: 'tm.pl.CONTACTS',
        state: true
      },
      onSidebar: false,
    },
    branding: cloneObject(templateBranding),
    summary: {
      state: true,
      title: {
        text: 'SUMMARY',
        pl: 'tm.pl.SUMMARY',
        state: true
      },
      desc: {
        text: '',
        pl: 'tm.pl.Write_brief_overview',
        hasEditor: true
      },
    },
    pureTemplate: true,
    type: TemplateTypes.ONE_ROW,
    sidebarPosition: 'left',
    templateTopPartType: 1,
    templateSidebarTopPart: 1,
    pages: {
      items: [
        {
          section: {
            items: [
              ...getSections([
                TemplateSectionTypes.WORK_EXPERIENCE,
                TemplateSectionTypes.EDUCATION,
                TemplateSectionTypes.SKILLS,
              ]),
              cloneObject(pureTemplateLanguages),
              cloneObject(pureTemplateInterests)
            ]
          },
          sidebarSection: {
            items: []
          },
          sidebarRightSection: {
            items: []
          },
          twoRowRightSideSection: {
            items: []
          },
          twoRowLeftSideSection: {
            items: []
          },
        },
      ]
    },
    allSections: {
      items: [
        ...getAllSectionsExpect([
          TemplateSectionTypes.WORK_EXPERIENCE,
          TemplateSectionTypes.EDUCATION,
          TemplateSectionTypes.SKILLS,
          TemplateSectionTypes.LANGUAGES,
          TemplateSectionTypes.INTERESTS,
          TemplateSectionTypes.CAUSES,
          TemplateSectionTypes.AREAS_OF_EXPERTISE,
        ]),
        cloneObject(pureTemplateCauses),
        cloneObject(pureTemplateAreasOfExp),
      ]
    },
    premium: false,
    settings: cloneObject(setSettings)
  },
  template7: {
    ...cloneObject(templatePart),
    summary: {
      state: true,
      title: {
        text: 'SUMMARY',
        pl: 'tm.pl.SUMMARY',
        state: false
      },
      desc: {
        text: '',
        pl: 'tm.pl.Write_brief_overview',
        hasEditor: true
      },
    },
    type: TemplateTypes.TWO_ROWS,
    sidebarPosition: 'right',
    templateTopPartType: 6,
    templateSidebarTopPart: 1,
    pages: {
      items: [
        {
          section: {
            items: []
          },
          sidebarSection: {
            items: []
          },
          sidebarRightSection: {
            items: []
          },
          twoRowRightSideSection: {
            items: [
              ...getSections([
                TemplateSectionTypes.WORK_EXPERIENCE,
                TemplateSectionTypes.SKILLS,
              ])
            ]
          },
          twoRowLeftSideSection: {
            items: [
              ...getSections([
                TemplateSectionTypes.EDUCATION,
                TemplateSectionTypes.LANGUAGES,
                TemplateSectionTypes.INTERESTS,
              ])
            ]
          },
        },
      ]
    },
    allSections: {
      items: [
        ...getAllSectionsExpect([
          TemplateSectionTypes.WORK_EXPERIENCE,
          TemplateSectionTypes.EDUCATION,
          TemplateSectionTypes.SKILLS,
          TemplateSectionTypes.LANGUAGES,
          TemplateSectionTypes.INTERESTS,
        ])
      ]
    },
    premium: true,
    settings: cloneObject(setSettings)
  },
};

export const allCoverLetterDefaults: AllCoverLetterDefaults = {
  coverLetter1: coverLetter1,
  coverLetter2: coverLetter2,
  coverLetter3: coverLetter3,
  coverLetter4: coverLetter4,
  coverLetter5: coverLetter5,
  coverLetter6: coverLetter6,
  coverLetter7: coverLetter7,
};
