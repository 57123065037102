import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {FooterComponent, HeaderComponent, TmPreviewComponent} from '@app/shared/ui-kits';

@Component({
    selector: 'sf-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss'],
    imports: [
        HeaderComponent,
        RouterOutlet,
        FooterComponent,
        TmPreviewComponent
    ]
})
export default class MainLayoutComponent {
}

