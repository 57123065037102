import {NgForOf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NzRadioModule} from "ng-zorro-antd/radio";
import {TranslateModule} from "@ngx-translate/core";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {Component, inject, Input} from '@angular/core';
import {NzPopoverDirective} from "ng-zorro-antd/popover";
import {ClickOutsideDirective} from "@app/shared/directives";
import {projectMessages} from "@app/shared/constants/project-messages";
import {AppStateService} from "@app/shared/services/app-state.service";
import {TemplateLogicService, TemplateService} from "@app/shared/services";
import {cloneObject, selectionToTheEnd, swapItems} from "@app/shared/helpers";
import {allSectionsItemsDefaults, templateSectionsShape} from '@app/shared/constants';
import {IconPickerComponent} from "@app/shared/ui-kits/icon-picker/icon-picker.component";
import {SectionNames, TemplateSectionTypes, TemplateSettingsShapes} from "@app/shared/enums";
import {TemplateConfigs, TemplateSection, TemplateSectionItem} from "@app/shared/interfaces";

@Component({
  selector: 'sf-list-actions-banner',
  templateUrl: './list-actions-banner.component.html',
  styleUrls: ['./list-actions-banner.component.scss'],
  imports: [
    IconPickerComponent,
    FormsModule,
    ClickOutsideDirective,
    NzRadioModule,
    NgForOf,
    NzToolTipModule,
    NzPopoverDirective,
    TranslateModule,
  ]
})
export class ListActionsBannerComponent {
  protected readonly templateService = inject(TemplateService);
  protected readonly appStateService = inject(AppStateService);
  private readonly templateLogicService = inject(TemplateLogicService);

  protected readonly TemplateSettingsShapes = TemplateSettingsShapes;
  protected readonly SectionNames = SectionNames;
  protected readonly TemplateSectionTypes = TemplateSectionTypes;

  protected readonly templateSectionsShape = cloneObject(templateSectionsShape);
  protected listSectionSettingsSelected = false;

  protected readonly projectMessages = projectMessages;

  @Input({required: true}) public template!: TemplateConfigs;

  @Input({required: true}) public section!: TemplateSection;
  @Input({required: true}) public sectionName!: SectionNames;
  @Input({required: true}) public ListSectionRef!: HTMLUListElement;

  @Input({required: true}) public sectionItem!: TemplateSectionItem;
  @Input({required: true}) public listIndex!: number;
  @Input({required: true}) public first!: boolean;
  @Input({required: true}) public last!: boolean;

  constructor() {
  }

  protected onAddListItem($event: Event, section: TemplateSection, index: number, listSectionEl: HTMLUListElement) {
    section.items.splice(index + 1, 0, cloneObject(allSectionsItemsDefaults[section.type]));

    setTimeout(() => {
      const el = listSectionEl.children[index + 1] as HTMLLIElement;
      el?.click();
      (el?.querySelector('.list-text') as HTMLDivElement).focus();
      this.templateLogicService.splitToPages(this.template);
    });

    $event.preventDefault();

    this.saveChanges();
  }

  protected onRemoveListItem($event: Event, items: TemplateSectionItem[], index: number, listSectionEl: HTMLUListElement) {
    if (items.length > 1) {
      if (!($event.target as HTMLLIElement).innerHTML.length) {
        items.splice(index, 1);

        setTimeout(() => {
          const prevEl = listSectionEl.children[index - 1]?.querySelector('div');
          if (prevEl) {
            prevEl.focus();
            selectionToTheEnd(prevEl);
          }
        });

        this.templateLogicService.splitToPages(this.template);
      }
    }
  }

  protected onListMoveUp(section: TemplateSection, index: number, listSectionRef: HTMLUListElement) {
    swapItems(section.items, index, index - 1);

    setTimeout(() => {
      const el = (listSectionRef.children[index - 1] as HTMLElement);
      const focusEL: HTMLDivElement | null = el.querySelector('.list-text');
      if (focusEL) {
        focusEL.focus();
        selectionToTheEnd(focusEL);
      }
    });

    this.saveChanges();
  }

  protected onListMoveDown(section: TemplateSection, index: number, listSectionRef: HTMLUListElement) {
    swapItems(section.items, index, index + 1);


    setTimeout(() => {
      const el = (listSectionRef.children[index + 1] as HTMLElement);
      const focusEL: HTMLDivElement | null = el.querySelector('.list-text');

      if (focusEL) {
        focusEL.focus();
        selectionToTheEnd(focusEL);
      }
    });

    this.saveChanges();
  }

  protected onRemoveListSectionItem(section: TemplateSection, index: number, listSectionRef: HTMLUListElement) {
    section.items.splice(index, 1);

    setTimeout(() => {
      let el = null;

      if (index === listSectionRef.children.length) {
        el = (listSectionRef.children[index - 1] as HTMLElement);
      }

      if (index < listSectionRef.children.length) {
        el = (listSectionRef.children[index] as HTMLElement);
      }

      const focusEL: HTMLDivElement | null | undefined = el?.querySelector('.list-text');
      if (focusEL) {
        focusEL.focus();
        selectionToTheEnd(focusEL);
      }
    });

    this.saveChanges();
  }

  protected onSelectListSectionSettings($event: Event) {
    $event.stopPropagation();
    this.listSectionSettingsSelected = !this.listSectionSettingsSelected;
  }

  protected onSelectInfTextOption(sectionItem: TemplateSectionItem, text: { name: string }) {
    this.section.settings!.selectedShape!.infographicValue = '';
    sectionItem.textValue = text.name;
    this.saveChanges();
  }

  protected onSelectChip(section: TemplateSection, item: { type: string; className: string; }) {
    if (section.settings!.selectedShape!.chipValue !== item.type) {
      section.settings!.selectedShape!.chipValue = item.type;

      this.templateLogicService.splitToPages(this.template);
      this.saveChanges();
    }
  }

  protected onSelectInfographics(section: TemplateSection, inf: { type: string; paid: boolean; spans: boolean }) {
    if (section.settings!.selectedShape!.infographicValue !== inf.type) {
      section.settings!.selectedShape!.infographicValue = inf.type;
      this.templateLogicService.splitToPages(this.template);
      this.saveChanges();
      this.appStateService.upgradeBannerState$.next();
    }
  }

  protected onChangedShapeType(type: TemplateSettingsShapes, section: TemplateSection) {
    switch (type) {
      case TemplateSettingsShapes.TEXT: {
        section.settings!.selectedShape!.infographicValue = '';

        if (section.settings!.listTypeOnSidebar === 'grid') {
          section.settings!.listTypeOnSidebar = 'list';
        }
        break;
      }
      case TemplateSettingsShapes.TEXT_OPTIONS: {
        section.settings!.selectedShape!.infographicValue = '';

        this.section.items.forEach((item) => {
          if (!item.textValue) {
            item.textValue = templateSectionsShape.text.items[0].name;
          }
        });
        break;
      }
      case TemplateSettingsShapes.INFOGRAPHICS: {
        section.settings!.selectedShape!.infographicValue = templateSectionsShape.infographics.items[0].type;
        break;
      }
    }

    this.templateLogicService.splitToPages(this.template);
    this.saveChanges();
  }

  protected changeListTOGridOrBack(section: TemplateSection, type: 'list' | 'wrap' | 'grid') {
    section.settings!.listTypeOnSidebar = type;

    this.templateLogicService.splitToPages(this.template);
    this.saveChanges();
  }

  protected saveChanges() {
    this.appStateService.saveChanges$.next();
  }

  protected onChangeTagCheckbox(event: Event) {
    if (!(event.target as HTMLInputElement).checked) {
      this.section.settings!.selectedShape.chipValue = '';
      this.saveChanges();
    }
  }

}

