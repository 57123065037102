import {FormsModule} from "@angular/forms";
import {NzSwitchModule} from "ng-zorro-antd/switch";
import {TranslateService} from "@ngx-translate/core";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {SectionItemDate} from "@app/shared/interfaces";
import {NzCarouselModule} from "ng-zorro-antd/carousel";
import {NzSegmentedModule} from "ng-zorro-antd/segmented";
import {NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {NzSegmentedOptions} from "ng-zorro-antd/segmented/types";
import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';

const getYears = (currentDate: Date): number[] => {
  const currentYear = currentDate.getFullYear();
  const years: number[] = [];

  for (let i = currentYear; i >= currentYear - 71; i--) {
    years.push(i);
  }

  return years;
}

const getAllMonthsAbbreviated = (): string[] => {
  const months: string[] = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  return months;
}

@Component({
  selector: 'sf-tm-date-picker',
  templateUrl: './tm-date-picker.component.html',
  styleUrls: ['./tm-date-picker.component.scss'],
  imports: [
    NzSegmentedModule,
    NgForOf,
    NzDividerModule,
    NzCarouselModule,
    NgIf,
    NzSwitchModule,
    FormsModule,
    NgTemplateOutlet
  ]
})
export class TmDatePickerComponent implements OnInit {
  private readonly translateService = inject(TranslateService);
  public readonly options: NzSegmentedOptions = [
    this.translateService.instant('segment.From'),
    this.translateService.instant('segment.To')
  ];
  public selectedOption = this.options[0];

  public years = getYears(new Date()).map((item) => item.toString());
  public months = getAllMonthsAbbreviated();

  public currentGroupIndex = 0;
  public itemsPerGroup = 9;

  @Input({required: true}) public period!: SectionItemDate;
  @Output() public periodChange = new EventEmitter<SectionItemDate>();

  ngOnInit() {
  }

  public onChangePresentState(state: boolean, presentInputEl: HTMLInputElement) {
    if (state) {
      presentInputEl.focus();
    }
    this.periodChange.emit(this.period);
  }

  public handleIndexChange(index: string | number): void {
    this.selectedOption = index as number;
  }

  public getCurrentGroup() {
    const startIndex = this.currentGroupIndex * this.itemsPerGroup;
    return this.years.slice(startIndex, startIndex + this.itemsPerGroup).reverse();
  }

  public goToNextGroup() {
    if (this.currentGroupIndex < this.years.length / this.itemsPerGroup - 1) {
      this.currentGroupIndex++;
    }
  }

  public goToPreviousGroup() {
    if (this.currentGroupIndex > 0) {
      this.currentGroupIndex--;
    }
  }

  public onSelectFromYear(year: string) {
    if (this.period.fromYear === year) {
      this.period.fromYear = '';
      this.period.fromMonth = '';
    } else {
      this.period.fromYear = year;
    }

    this.periodChange.emit(this.period);
  }

  public onSelectFromMonth(month: string) {
    if (this.period.fromMonth === month) {
      this.period.fromMonth = '';
    } else {
      this.period.fromMonth = month;
    }

    this.periodChange.emit(this.period);
  }

  public onSelectToYear(year: string) {
    if (this.period.toYear === year) {
      this.period.toYear = '';
      this.period.toMonth = '';
    } else {
      this.period.toYear = year;
    }

    this.periodChange.emit(this.period);
  }

  public onSelectToMonth(month: string) {
    if (this.period.toMonth === month) {
      this.period.toMonth = '';
    } else {
      this.period.toMonth = month;
    }

    this.periodChange.emit(this.period);
  }

  public onNameChanged() {
    this.periodChange.emit(this.period);
  }

}
