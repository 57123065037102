import {Subject} from "rxjs";
import {NgClass, NgStyle} from "@angular/common";
import {NzMessageService} from "ng-zorro-antd/message";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {AppStateService} from "@app/shared/services/app-state.service";
import {ContentEditableMaxLengthDirective} from "@app/shared/directives";
import {TemplateLogicService, TemplateService} from "@app/shared/services";
import {allSectionsItemsDefaults, templateSectionsShape} from "@app/shared/constants";
import {cloneObject, isOperationIncludes, selectionToTheEnd} from "@app/shared/helpers";
import {SectionNames, TemplateSectionTypes, TemplateSettingsShapes} from "@app/shared/enums";
import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ListActionsBannerComponent} from "@app/templates/componenets/list-actions-banner/list-actions-banner.component";
import {TitleActionBannerComponent} from "@app/templates/componenets/title-action-banner/title-action-banner.component";
import {
  TemplateInfographicsComponent
} from "@app/templates/componenets/template-infographics/template-infographics.component";
import {
  InFColor,
  TemplateConfigs,
  TemplatePage,
  TemplateSection,
  TemplateSectionItem,
  TemplateSettingsThemeColor
} from "@app/shared/interfaces";

@Component({
  selector: 'sf-list-section-list',
  templateUrl: './list-section-list.component.html',
  styleUrls: ['./list-section-list.component.scss'],
  imports: [
    NgStyle,
    NgClass,
    ListActionsBannerComponent,
    TemplateInfographicsComponent,
    TitleActionBannerComponent,
    ContentEditableMaxLengthDirective,
    TranslateModule,
  ]
})
export class ListSectionListComponent implements OnInit, OnDestroy {
  protected readonly appStateService = inject(AppStateService);
  protected readonly templateService = inject(TemplateService);
  private readonly translateService = inject(TranslateService);
  private readonly nzMessageService = inject(NzMessageService);
  private readonly templateLogicService = inject(TemplateLogicService);

  @Input({required: true}) public template!: TemplateConfigs;
  @Input({required: true}) public tmTheme!: TemplateSettingsThemeColor;

  @Input({required: true}) public page!: TemplatePage;
  @Input({required: true}) public pageIndex!: number;

  @Input({required: true}) public section!: TemplateSection;
  @Input({required: true}) public sectionIndex!: number;
  @Input({required: true}) public sectionName!: SectionNames;
  @Input({required: true}) public lastSection!: boolean;

  @Input({required: true}) public selectedItem: any = null;
  @Output() public selectedItemChange = new EventEmitter();

  @Input({required: true}) public selectedListItem: any = null;
  @Output() public selectedListItemChange = new EventEmitter();

  protected readonly TemplateSectionTypes = TemplateSectionTypes;
  private focusedElText = '';

  protected readonly SectionNames = SectionNames;

  @Output() protected openRearrange$ = new EventEmitter<void>();

  protected readonly TemplateSettingsShapes = TemplateSettingsShapes;
  private readonly destroy$ = new Subject<void>();

  // protected currentFontSize = this.appStateService.fontSizes.find((sizes) => sizes.name == TmFontSizesTypes.MEDIUM) as TemplateSettingsFontSize;

  ngOnInit() {
    if (!this.section.title.icon) {
      this.section.title.icon = {state: false, name: ''};
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  protected onSelect(item: any) {
    this.selectedItem = item;
    this.selectedItemChange.emit(this.selectedItem);
  }

  protected onSelectListItem(item: TemplateSectionItem, items: TemplateSectionItem[]) {
    this.selectedListItem = item;
    this.selectedListItemChange.emit(this.selectedListItem);
    this.onSelect(items);
  }

  protected onFocusEL($event: FocusEvent, text: string) {
    this.focusedElText = text;
  }

  protected getCheapColor(chipValue: string) {
    const tagsColors = this.tmTheme.tagsColors;

    switch (chipValue) {
      case 'bg-color-full': {
        return {
          backgroundColor: tagsColors?.bgColorFull.bgColor,
          borderColor: tagsColors?.bgColorFull.borderColor,
          color: tagsColors?.bgColorFull.color,
        }
      }
      case 'color-full': {
        return {
          backgroundColor: tagsColors?.colorFull.bgColor,
          borderColor: tagsColors?.colorFull.borderColor,
          color: tagsColors?.colorFull.color,
        }
      }
      case 'chip-initial': {
        return {
          backgroundColor: tagsColors?.initial.bgColor,
          borderColor: tagsColors?.initial.borderColor,
          color: tagsColors?.initial.color,
        }
      }
      default: {
        return {}
      }
    }
  }

  protected getInfColors(section: TemplateSection, isInSidebar: boolean): InFColor {
    const infColors = this.tmTheme.infColors;
    const chipValue = section.settings?.selectedShape!.chipValue;

    if (isInSidebar) {
      if (chipValue) {
        switch (chipValue) {
          case 'bg-color-full': {
            return infColors.tag.bgColorFull;
          }
          case 'color-full': {
            return infColors.tag.colorFull;
          }
          case 'chip-initial': {
            return infColors.tag.initial;
          }
          default: {
            return infColors.tag.initial;
          }
        }
      } else {
        if (this.selectedItem === section.items) {
          return infColors?.initial;
        }
        return infColors?.onDarkBg;
      }
    } else {
      if (chipValue) {
        switch (chipValue) {
          case 'bg-color-full': {
            return infColors.tag.bgColorFull;
          }
          case 'color-full': {
            return infColors.tag.colorFull;
          }
          case 'chip-initial': {
            return infColors.tag.initial;
          }
          default: {
            return infColors.tag.initial;
          }
        }
      } else {
        return infColors?.initial;
      }
    }
  }

  /** List Action Banner Start */

  protected onAddListItem($event: Event, section: TemplateSection, index: number, listSectionEl: HTMLUListElement) {

    const newItem = cloneObject(allSectionsItemsDefaults[section.type])

    section.items.splice(index + 1, 0, newItem);

    setTimeout(() => {
      const el = listSectionEl.children[index + 1] as HTMLLIElement;
      el?.click();
      (el?.querySelector('.list-text') as HTMLDivElement).focus();
      this.templateLogicService.splitToPages(this.template);
    });

    if (section.settings?.selectedShape.type === TemplateSettingsShapes.TEXT_OPTIONS) {
      newItem.textValue = templateSectionsShape.text.items[0].name;
    }

    $event.preventDefault();

    this.saveChanges();
  }

  protected onRemoveListItem($event: Event, items: TemplateSectionItem[], index: number, listSectionEl: HTMLUListElement) {

    const el = ($event.target as HTMLLIElement);
    setTimeout(() => {
      if (el.innerHTML === '<br>') {
        el.innerHTML = '';
      }
    });

    if (items.length > 1) {
      if (!el.innerHTML.length) {
        items.splice(index, 1);

        setTimeout(() => {
          const prevEl = listSectionEl.children[index - 1]?.querySelector('div');
          if (prevEl) {
            prevEl.focus();
            selectionToTheEnd(prevEl);
            this.templateLogicService.splitToPages(this.template);
            this.saveChanges();
          }
        });
      }
    }
  }

  /** List Action Banner End */

  protected onBlurListItem($event: FocusEvent, item: TemplateSectionItem) {
    item.text = ($event.target as HTMLDivElement).innerHTML;

    if (this.focusedElText !== item.text) {
      this.saveChanges();
    }
  }

  protected onFocusListItem(item: TemplateSectionItem, items: TemplateSectionItem[], text: string) {
    this.focusedElText = text;
    this.onSelectListItem(item, items);
  }

  /** Sections Length Prevent Start */

  protected elementKeydown($event: KeyboardEvent, size: number) {
    const textEl = ($event.target as HTMLElement);

    if (textEl.innerText.trim().length === 0) {
      textEl.innerHTML = '';
    }

    if (textEl.clientHeight > this.appStateService.descSectionMaxHeight && ![...this.appStateService.skipKeys, ...this.appStateService.skipMessageKeys].includes($event.key) && !isOperationIncludes($event)) {
      $event.preventDefault();
      this.nzMessageService.info(this.translateService.instant('project_messages.too_big_size'));
    }

    if (textEl.innerText.length > size && ![...this.appStateService.skipKeys, ...this.appStateService.skipMessageKeys].includes($event.key) && !isOperationIncludes($event)) {
      $event.preventDefault();

      if (!this.appStateService.skipMessageKeys.includes($event.key)) {
        this.nzMessageService.info(this.translateService.instant('project_messages.too_long_text'));
        return;
      }
    }
  }

  /** Sections Length Prevent End  */

  /** On Blurs Start */

  protected onBlurSectionTitle($event: FocusEvent, section: TemplateSection) {
    section.title.text = ($event.target as HTMLHeadElement).innerHTML;
    const sameSections = this.template.pages.items.map((page) => page.section.items.find((item) => item.type === section.type));
    sameSections.forEach((item) => item?.title === section.title);

    if (this.focusedElText !== section.title.text) {
      this.saveChanges();
      this.focusedElText = '';
    }
  }

  /** On Blurs End */


  protected getH2Color() {

    const color = this.tmTheme;

    if (this.sectionName === SectionNames.SIDEBAR_SECTION) {
      if (this.selectedItem === this.section.title) {
        return color.sidebar.sectionMainTitleFontEditModeColor;
      } else {
        return color.sidebar.sectionMainTitleFontColor;
      }
    } else {
      if (this.selectedItem === this.section.title) {
        return color.sectionMainTitleFontEditModeColor;
      } else {
        return color.sectionMainTitleFontColor;
      }
    }
  }

  protected saveChanges() {
    this.appStateService.saveChanges$.next();
  }

}

