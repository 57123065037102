import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {DeleteUserReason, User} from "@app/shared/interfaces";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly httpClient = inject(HttpClient);

  public updateUser(user: User): Observable<User> {
    return this.httpClient.post<User>(
      'user-profile',
      {...user}
    )
  }

  public updateUserImage(userId: string, blob: Blob) {
    return this.httpClient.post<{ src: string }>(`user/image/${userId}`, this.createFormData(blob))
  }

  public deleteUserImage(userId: string) {
    return this.httpClient.delete<{ success: string }>(`user/image/${userId}`);
  }

  private createFormData(file: Blob): FormData {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  }

  public updateUserPassword(body: any): Observable<any> {
    return this.httpClient.post('user/change-password', {...body}, {
      withCredentials: true
    });
  }

  public deleteUser(reason: DeleteUserReason): Observable<any> {
    return this.httpClient.post('user/user-delete', {...reason}, {
      withCredentials: true
    });
  }

  public confirmEmail(token: string) {
    return this.httpClient.post<{ success: boolean }>('user/verify_email', {token});
  }

}

