import {NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NzPopconfirmModule} from "ng-zorro-antd/popconfirm";
import {SectionNames, TemplateTypes} from "@app/shared/enums";
import {allSectionsItemsDefaults} from "@app/shared/constants";
import {AppStateService} from "@app/shared/services/app-state.service";
import {TemplateLogicService, TemplateService} from "@app/shared/services";
import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {TemplateConfigs, TemplatePage, TemplateSection} from "@app/shared/interfaces";
import {
  cloneObject,
  getOneRowSectionsByType,
  getSidebarSectionsByType,
  getTwoRowsSectionsByType,
  moveObjectsInArray
} from "@app/shared/helpers";

@Component({
  selector: 'sf-title-action-banner',
  templateUrl: './title-action-banner.component.html',
  styleUrls: ['./title-action-banner.component.scss'],
  imports: [
    NzToolTipModule,
    NgIf,
    NzPopconfirmModule,
    TranslateModule
  ]
})
export class TitleActionBannerComponent {
  protected readonly templateService = inject(TemplateService);
  protected readonly appStateService = inject(AppStateService);
  private readonly templateLogicService = inject(TemplateLogicService);

  @Input({required: true}) public template!: TemplateConfigs;

  @Input({required: true}) public page!: TemplatePage;
  @Input({required: true}) public pageIndex!: number;

  @Input({required: true}) public elRef!: HTMLDivElement | HTMLUListElement;

  @Input({required: true}) public section!: TemplateSection;
  @Input({required: true}) public sectionIndex!: number;
  @Input({required: true}) public sectionName!: SectionNames;

  @Input() public isList = false;

  @Input({required: true}) public selectedItem: any = null;
  @Output() public readonly selectedItemChange = new EventEmitter();
  protected selectedListItem: any = null;

  @Output() protected openRearrange$ = new EventEmitter<void>();

  protected onAddNewEntry(section: TemplateSection, index: number, workExperienceListRef: HTMLElement) {
    section.items.splice(index + 1, 0, cloneObject(allSectionsItemsDefaults[section.type]));

    setTimeout(() => {
      const el = (workExperienceListRef.children[index + 1] as HTMLElement);
      el.click();
      el.querySelector('h3')?.focus();
      this.templateLogicService.splitToPages(this.template);
      this.saveChanges();
    });
  }

  protected onRemoveLastSectionItem(pageIndex: number, sectionIndex: number, sectionName: SectionNames) {
    const page = this.page;

    switch (this.template.type) {
      case TemplateTypes.ONE_ROW: {
        const allDeletedSection = getOneRowSectionsByType(this.template, this.section);

        this.section.items = allDeletedSection.map((section) => section.items).flat();

        this.template.pages.items.forEach((page) => {
          page.section.items = page.section.items.filter((section) => {
            if (this.section === section) {
              return true;
            } else {
              return section.type !== this.section.type;
            }
          });
        });
        break;
      }
      case TemplateTypes.SIDEBAR: {
        const allDraggedSections = getSidebarSectionsByType(this.template, this.section);

        this.section.items = allDraggedSections.map((item) => item.items).flat();

        console.log('draggedSection', this.section)

        this.template.pages.items.forEach((page) => {
          page.sidebarSection.items = page.sidebarSection.items.filter((item) => {
            if (this.section === item) {
              return true;
            } else {
              return item.type !== this.section.type;
            }
          });
        });

        this.template.pages.items.forEach((page) => {
          page.sidebarRightSection.items = page.sidebarRightSection.items.filter((item) => {
            if (this.section === item) {
              return true;
            } else {
              return item.type !== this.section.type;
            }
          });
        });

        break;
      }
      case TemplateTypes.TWO_ROWS: {
        const allDraggedSections = getTwoRowsSectionsByType(this.template, this.section);

        this.section.items = allDraggedSections.map((item) => item.items).flat();

        this.template.pages.items.forEach((page) => {
          page.twoRowLeftSideSection.items = page.twoRowLeftSideSection.items.filter((item) => {
            if (this.section === item) {
              return true;
            } else {
              return item.type !== this.section.type;
            }
          });
        });

        this.template.pages.items.forEach((page) => {
          page.twoRowRightSideSection.items = page.twoRowRightSideSection.items.filter((item) => {
            if (this.section === item) {
              return true;
            } else {
              return item.type !== this.section.type;
            }
          });
        });
      }
    }

    this.page[sectionName].items.splice(sectionIndex, 1);
    this.template.allSections.items.push(this.section);

    if (this.template.pages.items.length > 1) {

      switch (this.template.type) {
        case TemplateTypes.ONE_ROW: {
          if (page.section.items.length === 0) {
            this.template.pages.items.splice(pageIndex, 1);
          }
          break;
        }
        case TemplateTypes.SIDEBAR: {
          if (page.sidebarSection.items.length === 0 && page.sidebarRightSection.items.length === 0) {
            this.template.pages.items.splice(pageIndex, 1);
          }
          break;
        }
        case TemplateTypes.TWO_ROWS: {
          if (page.twoRowLeftSideSection.items.length === 0 && page.twoRowRightSideSection.items.length === 0) {
            this.template.pages.items.splice(pageIndex, 1);
          }
          break;
        }
      }

    }

    this.selectedItem = null;
    this.selectedItemChange.emit(this.selectedItem);

    this.selectedListItem = null;

    setTimeout(() => this.templateLogicService.splitToPages(this.template));
    this.saveChanges();
  }

  protected onAddListItem($event: any, section: TemplateSection, index: number, listSectionEl: HTMLElement) {
    section.items.splice(index + 1, 0, cloneObject(allSectionsItemsDefaults[section.type]));

    setTimeout(() => {
      const el = listSectionEl.children[index + 1] as HTMLLIElement;
      el?.click();
      (el?.querySelector('.list-text') as HTMLDivElement).focus();
      this.templateLogicService.splitToPages(this.template);
    });

    $event.preventDefault();

    this.saveChanges();
  }

  protected openRearrangeSections() {
    this.openRearrange$.emit();
    this.selectedItem = null;
    this.selectedItemChange.emit(this.selectedItem);
    this.selectedListItem = null;
  }

  protected moveSectionUp(pageIndex: number, sectionIndex: number, sectionName: SectionNames) {
    const page = this.template.pages.items[pageIndex];
    moveObjectsInArray(page[sectionName].items, sectionIndex, sectionIndex - 1);
    this.saveChanges();
  }

  protected moveSectionDown(pageIndex: number, sectionIndex: number, sectionName: SectionNames) {
    const page = this.template.pages.items[pageIndex];
    moveObjectsInArray(page[sectionName].items, sectionIndex, sectionIndex + 1);
    this.saveChanges();
  }

  private saveChanges() {
    this.appStateService.saveChanges$.next();
  }

}
