import {FormsModule} from "@angular/forms";
import {NzMenuModule} from "ng-zorro-antd/menu";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzSwitchModule} from "ng-zorro-antd/switch";
import {TranslateModule} from "@ngx-translate/core";
import {TemplateService} from "@app/shared/services";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {TmSettings} from "@app/shared/types/template-types";
import {TemplateSocialInfoItem} from "@app/shared/interfaces";
import {TemplateTypes, TmSocialInfos} from "@app/shared/enums";
import {Component, inject, Input, OnDestroy} from '@angular/core';
import {AppStateService} from "@app/shared/services/app-state.service";
import {CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'sf-social-info-settings',
  templateUrl: './social-info-settings.component.html',
  styleUrls: ['./social-info-settings.component.scss'],
  imports: [
    CdkDrag,
    CdkDragHandle,
    CdkDropList,
    FormsModule,
    NzDropDownModule,
    NzMenuModule,
    NzToolTipModule,
    NzIconModule,
    NzSwitchModule,
    TranslateModule
  ]
})
export class SocialInfoSettingsComponent implements OnDestroy {
  protected readonly appStateService = inject(AppStateService);
  protected readonly templateService = inject(TemplateService);
  @Input({required: true}) public template!: TmSettings;

  protected readonly TemplateTypes = TemplateTypes;

  ngOnDestroy() {
    const linkedin = this.template.socialInfo.items
      .find((item) => item.type === TmSocialInfos.LINKEDIN)!;

    if (linkedin.text !== '' && !linkedin.text.startsWith('http')) {
      linkedin.text = `https://${linkedin.text}`;
    }

  }

  protected onMoveSocialInfo(event: CdkDragDrop<TemplateSocialInfoItem[]>) {
    moveItemInArray(this.template.socialInfo.items, event.previousIndex, event.currentIndex);
    this.saveChanges();
  }

  protected changeSocialLinkShorten(item: TemplateSocialInfoItem) {
    item.settings!.shorten = !item.settings!.shorten;
    this.saveChanges();
  }

  protected onToggleSocialInfoState(item: TemplateSocialInfoItem) {
    item.state = !item.state;
    this.saveChanges();
  }

  protected toggleSidebarPosition() {
    this.template.socialInfo.onSidebar = !this.template.socialInfo.onSidebar;
    this.saveChanges();
  }

  protected toggleSidebarTitle() {
    this.template.socialInfo.title.state = !this.template.socialInfo.title.state;
    this.saveChanges();
  }

  protected saveChanges() {
    this.appStateService.saveChanges$.next();
  }

}
