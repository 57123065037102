import {Renderer2} from "@angular/core";

// export const updateCanonicalURL = (url: string, document: Document, renderer: Renderer2) => {
//   const canonicalURL = url;
//   const head = document.getElementsByTagName('head')[0];
//
//   // Remove any existing canonical tags to avoid duplication
//   const existingLink = head.querySelector(`link[rel='canonical']`);
//
//   console.log('existingLink', existingLink);
//
//   if (existingLink) {
//     renderer.removeChild(head, existingLink);
//   }
//
//   // Create the new canonical tag
//   const link: HTMLLinkElement = renderer.createElement('link');
//   renderer.setAttribute(link, 'rel', 'canonical');
//   renderer.setAttribute(link, 'href', canonicalURL);
//
//   // Append the new canonical tag to the head
//   renderer.appendChild(head, link);
// }

export const updateCanonicalURL = (url: string, document: Document, renderer: Renderer2) => {
  const head = document.getElementsByTagName('head')[0];

  // Check if a canonical tag already exists
  const existingLink = head.querySelector(`link[rel='canonical']`);

  if (existingLink) {
    // Update the href attribute of the existing canonical tag
    renderer.setAttribute(existingLink, 'href', url);
  } else {
    // Create a new canonical tag if it doesn't exist
    const link: HTMLLinkElement = renderer.createElement('link');
    renderer.setAttribute(link, 'rel', 'canonical');
    renderer.setAttribute(link, 'href', url);

    // Append the new canonical tag to the head
    renderer.appendChild(head, link);
  }
};

