import {Params} from '@angular/router';
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {SfFilterPipe} from "@app/shared/pipes";
import {TranslateModule} from "@ngx-translate/core";
import {animate, style, transition, trigger} from '@angular/animations';
import {ClickOutsideDirective, ElPositionDirective} from "@app/shared/directives";
import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'sf-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  imports: [
    FormsModule,
    ElPositionDirective,
    ClickOutsideDirective,
    NgIf,
    SfFilterPipe,
    NgForOf,
    TranslateModule,
  ],
  animations: [
    trigger('dropdownState', [
      transition(':enter', [
        style({
          transform: 'translate(0, 20px)',
          opacity: 0
        }),
        animate(200)
      ]),
      transition(':leave', [
        animate(200),
        style({
          transform: 'translate(0, 20px)',
          opacity: 0
        })
      ]),
    ])
  ]
})
export class DropdownComponent {

  public dropdownState = false;
  public searchQuery = '';


  @ViewChild('SearchRef') searchRef: ElementRef<HTMLInputElement> | null = null;

  @Input() public selectedItem: any | null = null;
  @Output() public selectedItemChange = new EventEmitter<any>();

  @Input() public items: any[] = [];
  @Input() public showTitle = false;
  @Input() public keyName = 'name';
  @Input() public placeHolder = 'Select Item';
  @Input() public searchPlaceHolder = '';
  @Input() public closeItemsWhenSelected = true;
  @Input() public hasSearch = true;
  @Input() public disabled = false;
  @Input() public label = '';
  @Input() public unSelectable = true;
  @Input() public hideByKeyName = '';
  @Input() public mainClassName = 'sw-dropdown';
  @Input() additionalClassName = '';

  @Output() public select$ = new EventEmitter();
  @Output() public unset$ = new EventEmitter<void>();


  public onSelectItem(item: Params): void {
    if (this.selectedItem !== item) {
      this.selectedItem = item;
      this.select$.emit(item);
      this.selectedItemChange.emit(this.selectedItem);
    }
    this.closeItems();
  }

  public onUnset(): void {
    this.selectedItem = null;
    this.unset$.emit();
    this.closeItems();
  }

  private closeItems(): void {
    if (this.closeItemsWhenSelected) {
      this.actionsBeforeClose();
    }
  }

  public onClickOutside(): void {
    this.actionsBeforeClose();
  }

  private actionsBeforeClose(): void {
    this.dropdownState = false;
    this.searchQuery = '';
  }

  protected onToggleDropdownState() {
    this.dropdownState = !this.dropdownState;

    setTimeout(() => {
      this.searchRef?.nativeElement.focus();
    }, 100);

  }

}
