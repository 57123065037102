import mixpanel from 'mixpanel-browser';
import {routes} from "@app/app-routing";
import en from '@angular/common/locales/en';
import {en_US, NZ_I18N} from "ng-zorro-antd/i18n";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {NzMessageService} from "ng-zorro-antd/message";
import {NZ_CONFIG, NzConfig} from "ng-zorro-antd/core/config";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NZ_MODAL_DATA, NzModalService} from "ng-zorro-antd/modal";
import {ApplicationConfig, inject, PLATFORM_ID} from '@angular/core';
import {provideTranslateService, TranslateLoader} from "@ngx-translate/core";
import {DatePipe, isPlatformServer, registerLocaleData} from "@angular/common";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {provideClientHydration, withEventReplay, withIncrementalHydration} from "@angular/platform-browser";
import {PreloadAllModules, provideRouter, UrlSerializer, withInMemoryScrolling, withPreloading} from "@angular/router";
import {
  HttpBackend,
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withJsonpSupport
} from "@angular/common/http";
import {
  cacheInterceptor,
  errorLogInterceptor,
  notifierInterceptor,
  tokenInterceptor,
  urlPrefixInterceptor
} from "@app/shared/interceptors";
import {TrailingSlashUrlSerializer} from "@app/shared/helpers";

/*Sentry.init({
  dsn: "https://915bd715b0e92d948a6e17385e92ec46@o4507443370983424.ingest.us.sentry.io/4507447541366784",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  release: 'selfcv-ui@1.0.0',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: environment.env,
});*/

// Near entry of your product, init Mixpanel
mixpanel.init(
  '9dad90d6f2877ce5fab01a7a165ea6d2',
  {
    debug: true,
    track_pageview: false,
    persistence: 'localStorage'
  }
);

const HttpLoaderFactory = (httpHandler: HttpBackend) => {
  const platformId = inject(PLATFORM_ID);

  const path = isPlatformServer(platformId)
    ? 'https://www.selfcv.com/assets/i18n/' // Absolute path for SSR
    : './assets/i18n/'; // Relative path for the browser

  return new TranslateHttpLoader(new HttpClient(httpHandler), path, '.json');
}

// export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
//   return new TranslateHttpLoader(http);
// }

registerLocaleData(en);

const ngZorroConfig: NzConfig = {
  message: {
    nzMaxStack: 1
  }
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideTranslateService({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    {provide: UrlSerializer, useClass: TrailingSlashUrlSerializer},
    DatePipe,
    NzNotificationService,
    NzMessageService,
    NzDrawerService,
    NzModalService,

    /*{
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // showDialog: true, // this show modal in your project to send feedback.
      }),
    },
    {provide: Sentry.TraceService, deps: [Router]},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },*/

    {provide: NZ_MODAL_DATA, useValue: null},
    {provide: NZ_I18N, useValue: en_US},
    {provide: NZ_CONFIG, useValue: ngZorroConfig},

    provideAnimationsAsync(),

    provideRouter(routes,
      withInMemoryScrolling({scrollPositionRestoration: 'enabled'}),
      withPreloading(PreloadAllModules)
    ),

    provideHttpClient(
      withJsonpSupport(),
      withFetch(),
      withInterceptors([
        urlPrefixInterceptor,
        cacheInterceptor,
        notifierInterceptor,
        errorLogInterceptor,
        tokenInterceptor
      ]),
    ),

    provideClientHydration(
      withEventReplay(),
      withIncrementalHydration(),
      // withHttpTransferCacheOptions({
      //   includeRequestsWithAuthHeaders: false,
      // })
    ),
  ]
};
