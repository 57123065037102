<div
  class="{{template.socialInfo.className}} list-section section-container social-info-sidebar pr-16"
  [class.has-title-icon]="template.socialInfo.title.icon.state">

  @if (template.socialInfo.title.state) {
    <div class="list-section-title-wrapper pb-4">

      @if (template.socialInfo.title.icon.state) {
        <i class="{{template.socialInfo.title.icon.name}}"></i>
      }

      <h2
        contenteditable="true"
        [class.edit-mode]="selectedItem === template.socialInfo.title"
        [innerHTML]="template.socialInfo.title.text"
        [attr.placeholder]="template.socialInfo.title.pl | translate"
        (keydown.enter)="$event.preventDefault()"
        (blur)="onBlurSectionTitle($event, template.socialInfo)"
        (focus)="onFocusEL($event, template.socialInfo.title.text)"
        [sfContentEditableMaxLength]="appStateService.textMaxSizes.sectionMainTitle"
        (click)="onSelect(template.socialInfo.title)"
        [ngStyle]="{
          fontSize: appStateService.templateCurrentFontSize.sectionMainTitle + 'px',
          lineHeight: appStateService.templateCurrentFontSize.sectionMainTitleLineHeight + 'px',
          color: (selectedItem === template.socialInfo.title ? tmTheme.sidebar.sectionMainTitleFontEditModeColor : tmTheme.sidebar.sectionMainTitleFontColor)
      }"
      ></h2>
    </div>
  }

  <div class="ul-wrapper">
    <ul class="list-sec-ul" (click)="openSocialInfoSettings$.emit()">
      @for (item of template.socialInfo.items; track item.type) {
        @if (item.state) {
          <li
            class="pl-0"
            [ngStyle]="{
              fontSize: appStateService.templateCurrentFontSize.socialInfo + 'px',
              lineHeight: appStateService.templateCurrentFontSize.socialInfoLineHeight + 'px',
            }">
            <i class="{{item.icon}}"></i>
            <ng-container [ngTemplateOutlet]="ContentPartRef" [ngTemplateOutletContext]="{item}"/>
          </li>
        }
      }
    </ul>
  </div>
</div>

<div class="sf-line"></div>


<ng-template #ContentPartRef let-item=item>
  @if (item.link && item.text) {

    @if (appStateService.onPrintPage()) {
      <a [href]="item.text" target="_blank" rel="noopener">
        {{ (item?.settings?.shorten ? item.settings!.shortenName : item.text) || item.pl }}
      </a>
    } @else {
      <a>
        {{ (item?.settings?.shorten ? item.settings!.shortenName : item.text) || item.pl }}
      </a>
    }

  } @else {
    {{ (item?.settings?.shorten ? item.settings!.shortenName : item.text) || item.pl }}
  }
</ng-template>
