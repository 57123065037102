import {FormsModule} from '@angular/forms';
import {LngRoutePipe} from "@app/shared/pipes";
import {NzIconModule} from 'ng-zorro-antd/icon';
import {Language} from "@app/shared/interfaces";
import {NgOptimizedImage} from '@angular/common';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {TranslateModule} from "@ngx-translate/core";
import {Component, inject, Renderer2} from '@angular/core';
import {redirectToTranslatedPath} from "@app/shared/helpers";
import {Router, RouterLink, RouterLinkActive} from '@angular/router';
import {projectMessages} from "@app/shared/constants/project-messages";
import {AppStateService} from "@app/shared/services/app-state.service";
import {DropdownComponent} from "@app/shared/ui-kits/dropdown/dropdown.component";
import {ProjectSocialIconsComponent} from "@app/shared/ui-kits/project-social-icons/project-social-icons.component";

@Component({
  selector: 'sf-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [
    RouterLink,
    FormsModule,
    NzIconModule,
    LngRoutePipe,
    NzSelectModule,
    TranslateModule,
    NgOptimizedImage,
    RouterLinkActive,
    DropdownComponent,
    ProjectSocialIconsComponent,
  ]
})
export class FooterComponent {
  private readonly router = inject(Router);
  private readonly renderer = inject(Renderer2);
  public readonly appStateService = inject(AppStateService);
  protected readonly projectMessages = projectMessages;

  public changeLanguage(event: Language) {
    this.appStateService.setLng(event.key);
    this.appStateService.setHtmlLangAttribute(event.key, this.renderer);
    this.appStateService.addMetaTags(this.appStateService.SEO_Configs[event.key], this.renderer);

    redirectToTranslatedPath(this.router, event.key, this.appStateService);
  }

}
