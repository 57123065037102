import {NgForOf, NgIf} from "@angular/common";
import {AppFilterPipe} from "@app/shared/pipes";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {ClickOutsideDirective} from "@app/shared/directives";
import {ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-icon-picker',
    templateUrl: './icon-picker.component.html',
    styleUrls: ['./icon-picker.component.scss'],
    imports: [
        NzDropDownModule,
        NzInputModule,
        ReactiveFormsModule,
        NgForOf,
        NzIconModule,
        AppFilterPipe,
        ClickOutsideDirective,
        NgIf
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconPickerComponent implements OnInit {
  @Input() public color = '';
  @Input() public icon = '';
  @Output() public iconChange = new EventEmitter<string>();
  @Input() public deletable = true;
  @Input({required: true}) public iconList: { name: string }[] = [];
  public searchTerm = new UntypedFormControl('');
  public openState = false;
  public prefix = 'ph';

  public ngOnInit(): void {
  }

  public onSelectIcon(icon: { name: string }): void {
    this.iconChange.emit(`${this.prefix} ${icon.name}`);
  }

  public removeIcon(): void {

  }

  public onOpenDropdown() {
    this.openState = !this.openState;
  }


  public onCloseDropdown() {
    this.openState = false;
  }
}
