import {catchError, tap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {LocalStorageKeys} from "@app/shared/enums";
import {Observable, switchMap, throwError} from 'rxjs';
import {JWT, MySocialUser, User} from "../../interfaces";
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AppStateService} from "@app/shared/services/app-state.service";
import {removeItemStore, setItemStore} from "@app/shared/helpers/storage";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly httpClient = inject(HttpClient);

  private readonly appStateService = inject(AppStateService);

  public register(user: User): Observable<JWT> {
    return this.httpClient.post<User>('user/register', user)
      .pipe(
        switchMap(() => this.login({email: user.email, password: user.password}))
      )
  }

  public login(body: { email: string, password: string }): Observable<JWT> {
    const formData = new FormData();
    formData.append("email", body.email);
    formData.append("password", body.password);
    return this.httpClient.post<JWT>('user/login', formData, {withCredentials: true})
      .pipe(
        tap((res: JWT) => this.setAuthToken(res.accessToken))
      );
  }

  public refreshToken(): Observable<JWT> {
    return this.httpClient.post<JWT>('user/refresh-token', null, {withCredentials: true})
      .pipe(
        tap((res: JWT) => this.setAuthToken(res.accessToken)),
        catchError((err: HttpErrorResponse) => {
          err.error.refreshTokenExpired = true;
          delete err.error.tokenExpired;
          return throwError(err);
        })
      );
  }

  public socialSignIn(user: MySocialUser): Observable<JWT> {
    return this.httpClient.post<JWT>(`user/social-sign-in`, user, {withCredentials: true})
      .pipe(
        tap((res: JWT) => this.setAuthToken(res.accessToken))
      );
  }

  public forgotPassword(userEmail: Partial<{ email: string | null }>): Observable<void> {
    return this.httpClient.post<void>(`user/forgot-password`, userEmail);
  }

  public changePassword(body: { newPassword: string, token: string }): Observable<void> {
    return this.httpClient.post<void>('user/reset-password', body);
  }

  public resendEmail(): Observable<string> {
    return this.httpClient.post<string>('user/email-verification-resend', null)
  }

  public getMe(): Observable<User> {
    return this.httpClient.get<User>('user/self')
      .pipe(
        tap((user: User) => {
          this.appStateService.isAuthenticated = true;
          [user.firstName, user.lastName] = user.fullName.split(' ');
        })
      );
  }

  public signOut(): Observable<any> {
    removeItemStore([LocalStorageKeys.jwtAccessToken, LocalStorageKeys.jwtRefreshToken]);
    return this.httpClient.post<void>('user/logout', null, {withCredentials: true});
  }

  /*public get isAuthenticated(): boolean {
    const token = this.token;
    return token?.trim().length > 0;
  }*/

  /*private get token(): string {
    return getItemStore(LocalStorageKeys.jwtAccessToken);
  }*/

  private setAuthToken(token: string) {
    setItemStore(LocalStorageKeys.jwtAccessToken, token);
    this.appStateService.authToken = token;
  }

}
