import {NgStyle} from "@angular/common";
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TemplateConfigs, TemplateSettingsThemeColor} from "@app/shared/interfaces";

@Component({
    selector: 'sf-empty-section',
    templateUrl: './empty-section.component.html',
    styleUrls: ['./empty-section.component.scss'],
    imports: [
        NgStyle
    ]
})
export class EmptySectionComponent {
  @Input({required: true}) public template!: TemplateConfigs;
  @Input({required: true}) public tmTheme!: TemplateSettingsThemeColor;
  @Input({required: true}) public firstPage!: boolean;
  @Output() select$ = new EventEmitter<void>();

  protected onAddSection() {
    this.select$.emit();
  }

}
