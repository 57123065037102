import {Router} from "@angular/router";
import {finalize} from "rxjs/operators";
import {Template} from "@app/shared/interfaces";
import {DocumentTypes} from "@app/shared/enums";
import {NgOptimizedImage} from '@angular/common';
import {NzImageModule} from "ng-zorro-antd/image";
import {NzSpinComponent} from "ng-zorro-antd/spin";
import {TranslateModule} from "@ngx-translate/core";
import {TmButtonsComponent} from "@app/shared/ui-kits";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {cloneObject, extractColorNumber} from "@app/shared/helpers";
import {AnalyticsService, TemplateService} from "@app/shared/services";
import {AppStateService} from "@app/shared/services/app-state.service";
import {ChangeDetectionStrategy, Component, DestroyRef, inject, input, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'sf-templates-section',
  templateUrl: './templates-section.component.html',
  styleUrls: ['./templates-section.component.scss'],
  imports: [
    NzImageModule,
    NgOptimizedImage,
    NzSpinComponent,
    TmButtonsComponent,
    TranslateModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatesSectionComponent implements OnInit, OnDestroy {
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  private readonly appStateService = inject(AppStateService);
  private readonly templateService = inject(TemplateService);
  private readonly analyticsService = inject(AnalyticsService);

  protected readonly DocumentTypes = DocumentTypes;

  protected selectedTemplate: Template | null = null;

  @Input() ngTitle = 'Resume';
  public h2Text = input.required<string>();
  public pText = input.required<string>();
  @Input({required: true}) templates: Template[] = [];

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.selectedTemplate = null
  }

  protected onPreviewTemplate($event: Event, template: Template) {
    $event.stopPropagation();
    this.appStateService.templatePreview$.next({$event, template});
    this.analyticsService.track("Template Previewed / cv-templates", {templateId: template.templateId});
  }

  protected onSelectTemplate(template: Template) {
    this.selectedTemplate = template;

    if (this.appStateService.isAuthenticated) {
      const templateCopy = cloneObject(template);
      templateCopy.settings.settings.theme.colorId = this.appStateService.getTmColors(template)[(extractColorNumber(template.src)! - 1) || 0].id;

      this.templateService.addTemplate(templateCopy)
        .pipe(
          finalize(() => this.selectedTemplate = null),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((res) => {
          this.router.navigate([this.appStateService.getLngRout('documents'), res.documentId]);
        });

      this.analyticsService.track("Template Choose / cv-templates", {
        templateId: templateCopy.templateId,
        isPremiumTemplate: templateCopy.premium
      });

    } else {
      this.router.navigate(
        [this.appStateService.getLngRout('auth')],
        {
          queryParams: {
            returnUrl: this.appStateService.getLngRout(this.ngTitle === 'CV' ? 'cv_templates' : 'cover_letters')
          }
        }
      );
    }
  }

}

