<div class="user-delete-main-container feedback-cnt">
  <form [formGroup]="feedbackForm" #form="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
    <div class="delete-part-container">

      <div class="top-part">
        <h2>{{ 'send_feedback.welcome_feedback' | translate }}</h2>
        <p>{{ 'send_feedback.improve_our_product' | translate }}</p>
      </div>

      <div class="mt-20">{{ 'send_feedback.rate_us' | translate }}</div>
      <nz-rate formControlName="stars"/>

      <nz-divider/>

      @if (!appStateService.user) {
        <div class="sf-input-wr mb-20">
          <input
            class="sf-input"
            type="text"
            [placeholder]="'placeholder.email' | translate"
            formControlName="email"
            sfTrim
            [class.error]="form.submitted && feedbackForm.controls['email'].errors">
          @if (form.submitted && feedbackForm.controls['email'].errors; as error) {
            <div class="sf-err-wr">

              @if (error['required']) {
                <div class="sf-input-err">
                  <i class="ph ph-warning"></i>
                  {{ 'validation.email_required' | translate }}
                </div>
              }

              @if (error['email']) {
                <div class="sf-input-err">
                  <i class="ph ph-warning"></i>
                  {{ 'validation.incorrect_email' | translate }}
                </div>
              }

            </div>
          }
        </div>
      }


      <div class="textarea-part">
        <p>{{ 'send_feedback.anything_else' | translate }}</p>

        <textarea
          class="sf-textarea"
          [autofocus]="false"
          [placeholder]="'placeholder.we_read_every_message_2' | translate"
          formControlName="feedback"
        ></textarea>
      </div>

      <div>
        <div class="clearfix mt-18">
          <nz-upload
            nzListType="picture"
            nzAccept="image/*, application/pdf, .txt, .csv"
            [nzMultiple]="true"
            [(nzFileList)]="fileList"
            [nzShowButton]="fileList.length < 4"
            [nzLimit]="4"
            [nzBeforeUpload]="beforeUpload">
            <button nz-button type="button" class="file-upload-btn">
              <span nz-icon nzType="upload"></span>
              {{ 'send_feedback.click_or_drag' | translate }}
            </button>
          </nz-upload>
        </div>
      </div>

      @if (form.submitted && feedbackForm.errors?.['requiredAtLeastOne']) {
        <div class="sf-info-banner error ph ph-x-circle mt-30">
          <div>
            <div>{{ 'error' | translate }}</div>
            <p>{{ 'send_feedback.rate_or' | translate }}</p>
          </div>
        </div>
      }

      @if (fileSizeError) {
        <div class="sf-info-banner error ph ph-x-circle mt-30">
          <div>
            <div>{{ 'error' | translate }}</div>
            <p>{{ 'send_feedback.file_limit' | translate }}</p>
          </div>
        </div>
      }

      <nz-divider/>

      <div class="buttons-part">
        <button type="button" class="sf-btn-tertiary small mr-16 w-100" (click)="onCloseModal()">
          {{ 'cancel' | translate }}
        </button>

        <button
          type="submit" class="sf-btn-primary small w-100" [class.loading]="btnLoaderState">
          {{ 'send_feedback.send' | translate }}
        </button>
      </div>

    </div>
  </form>

</div>

