import {SingleTemplateThemeColor} from "@app/shared/interfaces";

export const template7Themes: SingleTemplateThemeColor = {
  theme1: {
    id: 1,
    paid: false,

    fontColor: '#303030',
    emptyImgColor: '#4B57DC',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#4B57DC',
    subTitleFontEditModeColor: '#4B57DC',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#4B57DC',
    sectionMainTitleFontEditModeColor: '#4B57DC',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#4B57DC',

    dateColor: '#4B57DC',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#4B57DC',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#4B57DC',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#4B57DC',
      sectionMainTitleFontEditModeColor: '#4B57DC',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#4B57DC',
      sectionSubtitleEditModeFontColor: '#4B57DC',

      topPartBgColor: '#fff',

      dateColor: '#4B57DC',
      countryColor: '#374259',

      linkColor: '#4B57DC',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#4B57DC',
        borderColor: '#4B57DC',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#4B57DC',
        borderColor: '#4B57DC',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#4B57DC',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#4B57DC',
          borderColor: '#4B57DC',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#4B57DC',
          borderColor: '#4B57DC',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#4B57DC',
        borderColor: '#4B57DC',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#4B57DC',
        borderColor: '#4B57DC',
      }
    },
    emptySection: {
      bgColor: 'rgba(75,87,220, .2)',
      btnBgColor: '#4B57DC',
      btnBorderColor: '#4B57DC'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#4B57DC',
    demoColor2: '#2a3958',

    linkColor: '#4B57DC'
  },
  theme2: {
    id: 2,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#374259',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#69748B',
    subTitleFontEditModeColor: '#69748B',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#374259',
    sectionMainTitleFontEditModeColor: '#374259',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#69748B',

    dateColor: '#374259',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#374259',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#374259',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#374259',
      sectionMainTitleFontEditModeColor: '#374259',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#374259',
      sectionSubtitleEditModeFontColor: '#374259',

      topPartBgColor: '#fff',

      dateColor: '#374259',
      countryColor: '#374259',

      linkColor: '#374259',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#374259',
        borderColor: '#374259',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#374259',
        borderColor: '#374259',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#374259',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#374259',
          borderColor: '#374259',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#374259',
          borderColor: '#374259',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#374259',
        borderColor: '#374259',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#374259',
        borderColor: '#374259',
      }
    },
    emptySection: {
      bgColor: 'rgba(75,87,220, .2)',
      btnBgColor: '#374259',
      btnBorderColor: '#374259'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#374259',
    demoColor2: '#69748B',

    linkColor: '#374259'
  },
  theme3: {
    id: 3,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#647982',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#647982',
    subTitleFontEditModeColor: '#647982',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#647982',
    sectionMainTitleFontEditModeColor: '#647982',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#647982',

    dateColor: '#647982',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#647982',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#647982',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#647982',
      sectionMainTitleFontEditModeColor: '#647982',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#647982',
      sectionSubtitleEditModeFontColor: '#647982',

      topPartBgColor: '#fff',

      dateColor: '#647982',
      countryColor: '#374259',

      linkColor: '#647982',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#647982',
        borderColor: '#647982',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#647982',
        borderColor: '#647982',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#647982',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#647982',
          borderColor: '#647982',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#647982',
          borderColor: '#647982',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#647982',
        borderColor: '#647982',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#647982',
        borderColor: '#647982',
      }
    },
    emptySection: {
      bgColor: 'rgba(75,87,220, .2)',
      btnBgColor: '#647982',
      btnBorderColor: '#647982'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#324750',
    demoColor2: '#647982',

    linkColor: '#647982'
  },
  theme4: {
    id: 4,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#4E595D',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#4E595D',
    subTitleFontEditModeColor: '#4E595D',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#4E595D',
    sectionMainTitleFontEditModeColor: '#4E595D',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#4E595D',

    dateColor: '#4E595D',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#4E595D',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#4E595D',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#4E595D',
      sectionMainTitleFontEditModeColor: '#4E595D',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#4E595D',
      sectionSubtitleEditModeFontColor: '#4E595D',

      topPartBgColor: '#fff',

      dateColor: '#4E595D',
      countryColor: '#374259',

      linkColor: '#4E595D',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#4E595D',
        borderColor: '#4E595D',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#4E595D',
        borderColor: '#4E595D',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#4E595D',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#4E595D',
          borderColor: '#4E595D',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#4E595D',
          borderColor: '#4E595D',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#4E595D',
        borderColor: '#4E595D',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#4E595D',
        borderColor: '#4E595D',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#4E595D',
      btnBorderColor: '#4E595D'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#4E595D',
    demoColor2: '#808B8F',

    linkColor: '#4E595D'
  },
  theme5: {
    id: 5,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#828293',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#828293',
    subTitleFontEditModeColor: '#828293',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#828293',
    sectionMainTitleFontEditModeColor: '#828293',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#828293',

    dateColor: '#828293',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#828293',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#828293',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#828293',
      sectionMainTitleFontEditModeColor: '#828293',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#828293',
      sectionSubtitleEditModeFontColor: '#828293',

      topPartBgColor: '#fff',

      dateColor: '#828293',
      countryColor: '#374259',

      linkColor: '#828293',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#828293',
        borderColor: '#828293',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#828293',
        borderColor: '#828293',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#828293',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#828293',
          borderColor: '#828293',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#828293',
          borderColor: '#828293',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#828293',
        borderColor: '#828293',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#828293',
        borderColor: '#828293',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#828293',
      btnBorderColor: '#828293'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#828293',
    demoColor2: '#374259',

    linkColor: '#828293'
  },
  theme6: {
    id: 6,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#5B5054',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#5B5054',
    subTitleFontEditModeColor: '#5B5054',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#5B5054',
    sectionMainTitleFontEditModeColor: '#5B5054',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#5B5054',

    dateColor: '#5B5054',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#5B5054',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#5B5054',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#5B5054',
      sectionMainTitleFontEditModeColor: '#5B5054',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#5B5054',
      sectionSubtitleEditModeFontColor: '#5B5054',

      topPartBgColor: '#fff',

      dateColor: '#5B5054',
      countryColor: '#374259',

      linkColor: '#5B5054',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#5B5054',
        borderColor: '#5B5054',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#5B5054',
        borderColor: '#5B5054',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#5B5054',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#5B5054',
          borderColor: '#5B5054',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#5B5054',
          borderColor: '#5B5054',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#5B5054',
        borderColor: '#5B5054',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#5B5054',
        borderColor: '#5B5054',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#5B5054',
      btnBorderColor: '#5B5054'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#5B5054',
    demoColor2: '#A49393',

    linkColor: '#5B5054'
  },
  theme7: {
    id: 7,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#43779F',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#43779F',
    subTitleFontEditModeColor: '#43779F',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#43779F',
    sectionMainTitleFontEditModeColor: '#43779F',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#43779F',

    dateColor: '#43779F',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#43779F',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#43779F',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#43779F',
      sectionMainTitleFontEditModeColor: '#43779F',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#43779F',
      sectionSubtitleEditModeFontColor: '#43779F',

      topPartBgColor: '#fff',

      dateColor: '#43779F',
      countryColor: '#374259',

      linkColor: '#43779F',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#43779F',
        borderColor: '#43779F',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#43779F',
        borderColor: '#43779F',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#43779F',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#43779F',
          borderColor: '#43779F',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#43779F',
          borderColor: '#43779F',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#43779F',
        borderColor: '#43779F',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#43779F',
        borderColor: '#43779F',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#43779F',
      btnBorderColor: '#43779F'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#43779F',
    demoColor2: '#1B4F77',

    linkColor: '#43779F'
  },
  theme8: {
    id: 8,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#005188',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#005188',
    subTitleFontEditModeColor: '#005188',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#005188',
    sectionMainTitleFontEditModeColor: '#005188',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#005188',

    dateColor: '#005188',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#005188',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#005188',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#005188',
      sectionMainTitleFontEditModeColor: '#005188',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#005188',
      sectionSubtitleEditModeFontColor: '#005188',

      topPartBgColor: '#fff',

      dateColor: '#005188',
      countryColor: '#374259',

      linkColor: '#005188',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#005188',
        borderColor: '#005188',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#005188',
        borderColor: '#005188',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#005188',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#005188',
          borderColor: '#005188',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#005188',
          borderColor: '#005188',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#005188',
        borderColor: '#005188',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#005188',
        borderColor: '#005188',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#005188',
      btnBorderColor: '#005188'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#005188',
    demoColor2: '#2983BA',

    linkColor: '#005188'
  },
  theme9: {
    id: 9,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#3A3C73',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#3A3C73',
    subTitleFontEditModeColor: '#3A3C73',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#3A3C73',
    sectionMainTitleFontEditModeColor: '#3A3C73',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#3A3C73',

    dateColor: '#3A3C73',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#3A3C73',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#3A3C73',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#3A3C73',
      sectionMainTitleFontEditModeColor: '#3A3C73',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#3A3C73',
      sectionSubtitleEditModeFontColor: '#3A3C73',

      topPartBgColor: '#fff',

      dateColor: '#3A3C73',
      countryColor: '#374259',

      linkColor: '#3A3C73',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#3A3C73',
        borderColor: '#3A3C73',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#3A3C73',
        borderColor: '#3A3C73',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#3A3C73',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#3A3C73',
          borderColor: '#3A3C73',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#3A3C73',
          borderColor: '#3A3C73',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#3A3C73',
        borderColor: '#3A3C73',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#3A3C73',
        borderColor: '#3A3C73',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#3A3C73',
      btnBorderColor: '#3A3C73'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#3A3C73',
    demoColor2: '#6C6EA5',

    linkColor: '#3A3C73'
  },
  theme10: {
    id: 10,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#5A79C7',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#5A79C7',
    subTitleFontEditModeColor: '#5A79C7',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#5A79C7',
    sectionMainTitleFontEditModeColor: '#5A79C7',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#5A79C7',

    dateColor: '#5A79C7',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#5A79C7',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#5A79C7',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#5A79C7',
      sectionMainTitleFontEditModeColor: '#5A79C7',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#5A79C7',
      sectionSubtitleEditModeFontColor: '#5A79C7',

      topPartBgColor: '#fff',

      dateColor: '#5A79C7',
      countryColor: '#374259',

      linkColor: '#5A79C7',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#5A79C7',
        borderColor: '#5A79C7',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#5A79C7',
        borderColor: '#5A79C7',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#5A79C7',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#5A79C7',
          borderColor: '#5A79C7',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#5A79C7',
          borderColor: '#5A79C7',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#5A79C7',
        borderColor: '#5A79C7',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#5A79C7',
        borderColor: '#5A79C7',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#5A79C7',
      btnBorderColor: '#5A79C7'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#5A79C7',
    demoColor2: '#39559A',

    linkColor: '#5A79C7'
  },
  theme11: {
    id: 11,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#516D92',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#516D92',
    subTitleFontEditModeColor: '#516D92',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#516D92',
    sectionMainTitleFontEditModeColor: '#516D92',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#516D92',

    dateColor: '#516D92',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#516D92',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#516D92',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#516D92',
      sectionMainTitleFontEditModeColor: '#516D92',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#516D92',
      sectionSubtitleEditModeFontColor: '#516D92',

      topPartBgColor: '#fff',

      dateColor: '#516D92',
      countryColor: '#374259',

      linkColor: '#516D92',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#516D92',
        borderColor: '#516D92',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#516D92',
        borderColor: '#516D92',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#516D92',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#516D92',
          borderColor: '#516D92',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#516D92',
          borderColor: '#516D92',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#516D92',
        borderColor: '#516D92',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#516D92',
        borderColor: '#516D92',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#516D92',
      btnBorderColor: '#516D92'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#516D92',
    demoColor2: '#1F3B60',

    linkColor: '#516D92'
  },
  theme12: {
    id: 12,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#3C7680',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#3C7680',
    subTitleFontEditModeColor: '#3C7680',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#3C7680',
    sectionMainTitleFontEditModeColor: '#3C7680',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#3C7680',

    dateColor: '#3C7680',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#3C7680',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#3C7680',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#3C7680',
      sectionMainTitleFontEditModeColor: '#3C7680',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#3C7680',
      sectionSubtitleEditModeFontColor: '#3C7680',

      topPartBgColor: '#fff',

      dateColor: '#3C7680',
      countryColor: '#0A444E',

      linkColor: '#3C7680',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#3C7680',
        borderColor: '#3C7680',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#3C7680',
        borderColor: '#3C7680',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#3C7680',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#3C7680',
          borderColor: '#3C7680',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#3C7680',
          borderColor: '#3C7680',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#3C7680',
        borderColor: '#3C7680',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#3C7680',
        borderColor: '#3C7680',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#3C7680',
      btnBorderColor: '#3C7680'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#3C7680',
    demoColor2: '#1F3B60',

    linkColor: '#3C7680'
  },
  theme13: {
    id: 13,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#2A5752',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#2A5752',
    subTitleFontEditModeColor: '#2A5752',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#2A5752',
    sectionMainTitleFontEditModeColor: '#2A5752',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#2A5752',

    dateColor: '#2A5752',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#2A5752',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#2A5752',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#2A5752',
      sectionMainTitleFontEditModeColor: '#2A5752',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#2A5752',
      sectionSubtitleEditModeFontColor: '#2A5752',

      topPartBgColor: '#fff',

      dateColor: '#2A5752',
      countryColor: '#2A5752',

      linkColor: '#2A5752',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#2A5752',
        borderColor: '#2A5752',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#2A5752',
        borderColor: '#2A5752',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#2A5752',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#2A5752',
          borderColor: '#2A5752',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#2A5752',
          borderColor: '#2A5752',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#2A5752',
        borderColor: '#2A5752',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#2A5752',
        borderColor: '#2A5752',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#2A5752',
      btnBorderColor: '#2A5752'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#2A5752',
    demoColor2: '#527F7A',

    linkColor: '#2A5752'
  },
  theme14: {
    id: 14,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#519097',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#519097',
    subTitleFontEditModeColor: '#519097',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#519097',
    sectionMainTitleFontEditModeColor: '#519097',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#519097',

    dateColor: '#519097',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#519097',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#519097',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#519097',
      sectionMainTitleFontEditModeColor: '#519097',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#519097',
      sectionSubtitleEditModeFontColor: '#519097',

      topPartBgColor: '#fff',

      dateColor: '#519097',
      countryColor: '#519097',

      linkColor: '#519097',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#519097',
        borderColor: '#519097',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#519097',
        borderColor: '#519097',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#519097',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#519097',
          borderColor: '#519097',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#519097',
          borderColor: '#519097',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#519097',
        borderColor: '#519097',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#519097',
        borderColor: '#519097',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#519097',
      btnBorderColor: '#519097'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#519097',
    demoColor2: '#29686F',

    linkColor: '#519097'
  },
  theme15: {
    id: 15,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#728686',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#728686',
    subTitleFontEditModeColor: '#728686',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#728686',
    sectionMainTitleFontEditModeColor: '#728686',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#728686',

    dateColor: '#728686',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#728686',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#728686',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#728686',
      sectionMainTitleFontEditModeColor: '#728686',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#728686',
      sectionSubtitleEditModeFontColor: '#728686',

      topPartBgColor: '#fff',

      dateColor: '#728686',
      countryColor: '#728686',

      linkColor: '#728686',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#728686',
        borderColor: '#728686',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#728686',
        borderColor: '#728686',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#728686',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#728686',
          borderColor: '#728686',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#728686',
          borderColor: '#728686',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#728686',
        borderColor: '#728686',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#728686',
        borderColor: '#728686',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#728686',
      btnBorderColor: '#728686'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#728686',
    demoColor2: '#405454',

    linkColor: '#728686'
  },
  theme16: {
    id: 16,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#606C5D',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#606C5D',
    subTitleFontEditModeColor: '#606C5D',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#606C5D',
    sectionMainTitleFontEditModeColor: '#606C5D',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#606C5D',

    dateColor: '#606C5D',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#606C5D',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#606C5D',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#606C5D',
      sectionMainTitleFontEditModeColor: '#606C5D',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#606C5D',
      sectionSubtitleEditModeFontColor: '#606C5D',

      topPartBgColor: '#fff',

      dateColor: '#606C5D',
      countryColor: '#606C5D',

      linkColor: '#606C5D',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#606C5D',
        borderColor: '#606C5D',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#606C5D',
        borderColor: '#606C5D',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#606C5D',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#606C5D',
          borderColor: '#606C5D',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#606C5D',
          borderColor: '#606C5D',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#606C5D',
        borderColor: '#606C5D',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#606C5D',
        borderColor: '#606C5D',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#606C5D',
      btnBorderColor: '#606C5D'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#606C5D',
    demoColor2: '#7E8A7B', // 7E8A7B

    linkColor: '#606C5D'
  },
  theme17: {
    id: 17,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#86c1b7',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#86c1b7',
    subTitleFontEditModeColor: '#86c1b7',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#86c1b7',
    sectionMainTitleFontEditModeColor: '#86c1b7',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#86c1b7',

    dateColor: '#86c1b7',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#86c1b7',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#86c1b7',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#86c1b7',
      sectionMainTitleFontEditModeColor: '#86c1b7',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#86c1b7',
      sectionSubtitleEditModeFontColor: '#86c1b7',

      topPartBgColor: '#fff',

      dateColor: '#86c1b7',
      countryColor: '#86c1b7',

      linkColor: '#86c1b7',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#86c1b7',
        borderColor: '#86c1b7',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#86c1b7',
        borderColor: '#86c1b7',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#86c1b7',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#86c1b7',
          borderColor: '#86c1b7',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#86c1b7',
          borderColor: '#86c1b7',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#86c1b7',
        borderColor: '#86c1b7',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#86c1b7',
        borderColor: '#86c1b7',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#86c1b7',
      btnBorderColor: '#86c1b7'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#86c1b7',
    demoColor2: '#2B2E35', // 7E8A7B

    linkColor: '#86c1b7'
  },
  theme18: {
    id: 18,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#908071',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#908071',
    subTitleFontEditModeColor: '#908071',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#908071',
    sectionMainTitleFontEditModeColor: '#908071',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#908071',

    dateColor: '#908071',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#908071',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#908071',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#908071',
      sectionMainTitleFontEditModeColor: '#908071',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#908071',
      sectionSubtitleEditModeFontColor: '#908071',

      topPartBgColor: '#fff',

      dateColor: '#908071',
      countryColor: '#908071',

      linkColor: '#908071',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#908071',
        borderColor: '#908071',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#908071',
        borderColor: '#908071',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#908071',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#908071',
          borderColor: '#908071',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#908071',
          borderColor: '#908071',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#908071',
        borderColor: '#908071',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#908071',
        borderColor: '#908071',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#908071',
      btnBorderColor: '#908071'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#908071',
    demoColor2: '#5E4E3F', // 7E8A7B

    linkColor: '#908071'
  },
  theme19: {
    id: 19,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#8E6A64',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#8E6A64',
    subTitleFontEditModeColor: '#8E6A64',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#8E6A64',
    sectionMainTitleFontEditModeColor: '#8E6A64',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#8E6A64',

    dateColor: '#8E6A64',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#8E6A64',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#8E6A64',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#8E6A64',
      sectionMainTitleFontEditModeColor: '#8E6A64',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#8E6A64',
      sectionSubtitleEditModeFontColor: '#8E6A64',

      topPartBgColor: '#fff',

      dateColor: '#8E6A64',
      countryColor: '#8E6A64',

      linkColor: '#8E6A64',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#8E6A64',
        borderColor: '#8E6A64',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#8E6A64',
        borderColor: '#8E6A64',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#8E6A64',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#8E6A64',
          borderColor: '#8E6A64',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#8E6A64',
          borderColor: '#8E6A64',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#8E6A64',
        borderColor: '#8E6A64',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#8E6A64',
        borderColor: '#8E6A64',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#8E6A64',
      btnBorderColor: '#8E6A64'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#8E6A64',
    demoColor2: '#66423C',

    linkColor: '#8E6A64'
  },
  theme20: {
    id: 20,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#572B43',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#572B43',
    subTitleFontEditModeColor: '#572B43',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#572B43',
    sectionMainTitleFontEditModeColor: '#572B43',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#572B43',

    dateColor: '#572B43',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#572B43',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#572B43',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#572B43',
      sectionMainTitleFontEditModeColor: '#572B43',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#572B43',
      sectionSubtitleEditModeFontColor: '#572B43',

      topPartBgColor: '#fff',

      dateColor: '#572B43',
      countryColor: '#572B43',

      linkColor: '#572B43',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#572B43',
        borderColor: '#572B43',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#572B43',
        borderColor: '#572B43',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#572B43',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#572B43',
          borderColor: '#572B43',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#572B43',
          borderColor: '#572B43',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#572B43',
        borderColor: '#572B43',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#572B43',
        borderColor: '#572B43',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#572B43',
      btnBorderColor: '#572B43'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#572B43',
    demoColor2: '#7F536B',

    linkColor: '#572B43'
  },
  theme21: {
    id: 21,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#626262',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#626262',
    subTitleFontEditModeColor: '#626262',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#626262',
    sectionMainTitleFontEditModeColor: '#626262',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#626262',

    dateColor: '#626262',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#626262',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#626262',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#626262',
      sectionMainTitleFontEditModeColor: '#626262',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#626262',
      sectionSubtitleEditModeFontColor: '#626262',

      topPartBgColor: '#fff',

      dateColor: '#626262',
      countryColor: '#626262',

      linkColor: '#626262',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#626262',
        borderColor: '#626262',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#626262',
        borderColor: '#626262',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#626262',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#626262',
          borderColor: '#626262',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#626262',
          borderColor: '#626262',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#626262',
        borderColor: '#626262',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#626262',
        borderColor: '#626262',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#626262',
      btnBorderColor: '#626262'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#626262',
    demoColor2: '#303030',

    linkColor: '#626262'
  },
  theme22: {
    id: 22,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#BE6A9A',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#BE6A9A',
    subTitleFontEditModeColor: '#BE6A9A',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#BE6A9A',
    sectionMainTitleFontEditModeColor: '#BE6A9A',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#BE6A9A',

    dateColor: '#BE6A9A',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#BE6A9A',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#BE6A9A',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#BE6A9A',
      sectionMainTitleFontEditModeColor: '#BE6A9A',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#BE6A9A',
      sectionSubtitleEditModeFontColor: '#BE6A9A',

      topPartBgColor: '#fff',

      dateColor: '#BE6A9A',
      countryColor: '#BE6A9A',

      linkColor: '#BE6A9A',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#BE6A9A',
        borderColor: '#BE6A9A',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#BE6A9A',
        borderColor: '#BE6A9A',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#BE6A9A',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#BE6A9A',
          borderColor: '#BE6A9A',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#BE6A9A',
          borderColor: '#BE6A9A',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#BE6A9A',
        borderColor: '#BE6A9A',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#BE6A9A',
        borderColor: '#BE6A9A',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#BE6A9A',
      btnBorderColor: '#BE6A9A'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#BE6A9A',
    demoColor2: '#8C3868',

    linkColor: '#BE6A9A'
  },
  theme23: {
    id: 23,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#6B3A56',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#6B3A56',
    subTitleFontEditModeColor: '#6B3A56',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#6B3A56',
    sectionMainTitleFontEditModeColor: '#6B3A56',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#6B3A56',

    dateColor: '#6B3A56',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#6B3A56',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#6B3A56',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#6B3A56',
      sectionMainTitleFontEditModeColor: '#6B3A56',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#6B3A56',
      sectionSubtitleEditModeFontColor: '#6B3A56',

      topPartBgColor: '#fff',

      dateColor: '#6B3A56',
      countryColor: '#6B3A56',

      linkColor: '#6B3A56',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#6B3A56',
        borderColor: '#6B3A56',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#6B3A56',
        borderColor: '#6B3A56',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#6B3A56',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#6B3A56',
          borderColor: '#6B3A56',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#6B3A56',
          borderColor: '#6B3A56',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#6B3A56',
        borderColor: '#6B3A56',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#6B3A56',
        borderColor: '#6B3A56',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#6B3A56',
      btnBorderColor: '#6B3A56'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#6B3A56',
    demoColor2: '#9D6C88',

    linkColor: '#6B3A56'
  },
  theme24: {
    id: 24,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#E0589E',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#E0589E',
    subTitleFontEditModeColor: '#E0589E',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#E0589E',
    sectionMainTitleFontEditModeColor: '#E0589E',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#E0589E',

    dateColor: '#E0589E',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#E0589E',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#E0589E',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#E0589E',
      sectionMainTitleFontEditModeColor: '#E0589E',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#E0589E',
      sectionSubtitleEditModeFontColor: '#E0589E',

      topPartBgColor: '#fff',

      dateColor: '#E0589E',
      countryColor: '#E0589E',

      linkColor: '#E0589E',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#E0589E',
        borderColor: '#E0589E',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#E0589E',
        borderColor: '#E0589E',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#E0589E',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#E0589E',
          borderColor: '#E0589E',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#E0589E',
          borderColor: '#E0589E',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#E0589E',
        borderColor: '#E0589E',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#E0589E',
        borderColor: '#E0589E',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#E0589E',
      btnBorderColor: '#E0589E'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#E0589E',
    demoColor2: '#B05CAC',

    linkColor: '#E0589E'
  },
  theme25: {
    id: 25,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#8C6194',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#8C6194',
    subTitleFontEditModeColor: '#8C6194',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#8C6194',
    sectionMainTitleFontEditModeColor: '#8C6194',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#8C6194',

    dateColor: '#8C6194',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#8C6194',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#8C6194',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#8C6194',
      sectionMainTitleFontEditModeColor: '#8C6194',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#8C6194',
      sectionSubtitleEditModeFontColor: '#8C6194',

      topPartBgColor: '#fff',

      dateColor: '#8C6194',
      countryColor: '#8C6194',

      linkColor: '#8C6194',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#8C6194',
        borderColor: '#8C6194',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#8C6194',
        borderColor: '#8C6194',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#8C6194',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#8C6194',
          borderColor: '#8C6194',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#8C6194',
          borderColor: '#8C6194',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#8C6194',
        borderColor: '#8C6194',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#8C6194',
        borderColor: '#8C6194',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#8C6194',
      btnBorderColor: '#8C6194'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#8C6194',
    demoColor2: '#4C3551',

    linkColor: '#8C6194'
  },
  theme26: {
    id: 26,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#9B89BD',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#9B89BD',
    subTitleFontEditModeColor: '#9B89BD',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#9B89BD',
    sectionMainTitleFontEditModeColor: '#9B89BD',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#9B89BD',

    dateColor: '#9B89BD',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#9B89BD',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#9B89BD',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#9B89BD',
      sectionMainTitleFontEditModeColor: '#9B89BD',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#9B89BD',
      sectionSubtitleEditModeFontColor: '#9B89BD',

      topPartBgColor: '#fff',

      dateColor: '#9B89BD',
      countryColor: '#9B89BD',

      linkColor: '#9B89BD',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#9B89BD',
        borderColor: '#9B89BD',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#9B89BD',
        borderColor: '#9B89BD',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#9B89BD',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#9B89BD',
          borderColor: '#9B89BD',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#9B89BD',
          borderColor: '#9B89BD',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#9B89BD',
        borderColor: '#9B89BD',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#9B89BD',
        borderColor: '#9B89BD',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#9B89BD',
      btnBorderColor: '#9B89BD'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#9B89BD',
    demoColor2: '#736195',

    linkColor: '#9B89BD'
  },
  theme27: {
    id: 27,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#995D93',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#995D93',
    subTitleFontEditModeColor: '#995D93',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#995D93',
    sectionMainTitleFontEditModeColor: '#995D93',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#995D93',

    dateColor: '#995D93',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#995D93',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#995D93',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#995D93',
      sectionMainTitleFontEditModeColor: '#995D93',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#995D93',
      sectionSubtitleEditModeFontColor: '#995D93',

      topPartBgColor: '#fff',

      dateColor: '#995D93',
      countryColor: '#995D93',

      linkColor: '#995D93',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#995D93',
        borderColor: '#995D93',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#995D93',
        borderColor: '#995D93',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#995D93',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#995D93',
          borderColor: '#995D93',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#995D93',
          borderColor: '#995D93',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#995D93',
        borderColor: '#995D93',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#995D93',
        borderColor: '#995D93',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#995D93',
      btnBorderColor: '#995D93'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#995D93',
    demoColor2: '#672B61',

    linkColor: '#995D93'
  },
  theme28: {
    id: 28,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#8A6B9E',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#8A6B9E',
    subTitleFontEditModeColor: '#8A6B9E',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#8A6B9E',
    sectionMainTitleFontEditModeColor: '#8A6B9E',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#8A6B9E',

    dateColor: '#8A6B9E',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#8A6B9E',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#8A6B9E',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#8A6B9E',
      sectionMainTitleFontEditModeColor: '#8A6B9E',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#8A6B9E',
      sectionSubtitleEditModeFontColor: '#8A6B9E',

      topPartBgColor: '#fff',

      dateColor: '#8A6B9E',
      countryColor: '#8A6B9E',

      linkColor: '#8A6B9E',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#8A6B9E',
        borderColor: '#8A6B9E',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#8A6B9E',
        borderColor: '#8A6B9E',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#8A6B9E',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#8A6B9E',
          borderColor: '#8A6B9E',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#8A6B9E',
          borderColor: '#8A6B9E',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#8A6B9E',
        borderColor: '#8A6B9E',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#8A6B9E',
        borderColor: '#8A6B9E',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#8A6B9E',
      btnBorderColor: '#8A6B9E'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#8A6B9E',
    demoColor2: '#58396C',

    linkColor: '#8A6B9E'
  },
  theme29: {
    id: 29,
    paid: true,

    fontColor: '#303030',
    emptyImgColor: '#65758D',

    topPartBgColor: '#ffffff',

    mainTitleFontColor: '#303030',
    mainTitleFontEditModeColor: '#303030',

    subTitleFontColor: '#65758D',
    subTitleFontEditModeColor: '#65758D',
    subTitleBGColor: 'transparent',

    summaryTitleFontColor: '#303030',
    summaryTitleFontEditModeColor: '#303030',
    summaryFontColor: '#303030',
    summaryFontEditModeColor: '#303030',

    sectionMainTitleFontColor: '#65758D',
    sectionMainTitleFontEditModeColor: '#65758D',
    sectionMainTitleBgColor: 'transparent',

    sectionTitleFontColor: '#303030',
    sectionSubtitleFontColor: '#65758D',

    dateColor: '#65758D',
    countryColor: '#979797',

    socialInfo: {
      bgColor: '#ffffff',
      fontColor: '#303030',
      borderColor: '#65758D',
    },

    sidebar: {
      bgColor: '#fff',
      fontColor: '#000',
      emptyImgColor: '#65758D',

      border: {
        borderLeftColor: '#fff',
        borderRightColor: '#fff',
        borderTopColor: '#fff',
        borderBottomColor: '#fff',
      },

      sectionMainTitleFontColor: '#65758D',
      sectionMainTitleFontEditModeColor: '#65758D',

      sectionTitleFontColor: '#303030',
      sectionTitleFontEditModeColor: '#303030',

      sectionSubtitleFontColor: '#65758D',
      sectionSubtitleEditModeFontColor: '#65758D',

      topPartBgColor: '#fff',

      dateColor: '#65758D',
      countryColor: '#65758D',

      linkColor: '#65758D',
    },

    tagsColors: {
      bgColorFull: {
        bgColor: '#65758D',
        borderColor: '#65758D',
        color: '#ffffff'
      },
      initial: {
        bgColor: '#ffffff',
        borderColor: 'rgba(0,0,0,.1)',
        color: '#303030'
      },
      colorFull: {
        color: '#65758D',
        borderColor: '#65758D',
        bgColor: '#ffffff',
      },
    },

    infColors: {
      tag: {
        bgColorFull: {
          bgColor: '#65758D',
          activeBgColor: '#fff',
          borderColor: '#fff',
        },
        initial: {
          bgColor: '#fff',
          activeBgColor: '#65758D',
          borderColor: '#65758D',
        },
        colorFull: {
          bgColor: '#fff',
          activeBgColor: '#65758D',
          borderColor: '#65758D',
        }
      },

      initial: {
        bgColor: '#ffffff',
        activeBgColor: '#65758D',
        borderColor: '#65758D',
      },
      onDarkBg: {
        bgColor: 'transparent',
        activeBgColor: '#65758D',
        borderColor: '#65758D',
      }
    },
    emptySection: {
      bgColor: 'rgba(78,89,93, .2)',
      btnBgColor: '#65758D',
      btnBorderColor: '#65758D'
    },
    hover: {
      borderColor: '',
      bgColor: '',
    },

    branding: {
      color: 'rgba(48, 48, 48, 0.7)',
      hoverColor: 'rgba(48, 48, 48, 0.7)',
      sidebarColor: 'rgba(48, 48, 48, 0.7)',
      sidebarHoverColor: 'rgba(48, 48, 48, 0.7)',
    },

    demoColor1: '#65758D',
    demoColor2: '#3D4D65',

    linkColor: '#65758D'
  },
}
