<div
  class="
         {{section.className}}
         list-section section-container
         {{section.settings?.selectedShape!.chipValue}}
         {{section.settings?.selectedShape!.infographicValue}}"
  [class.has-title-icon]="section.title.icon.state"
  [class.has-chip]="section.settings?.selectedShape!.chipValue"
  [class.has-infographic]="section.settings?.selectedShape!.infographicValue"
  [class.has-icon]="section.settings!.selectedShape.hasIcon"
  [ngStyle]="{'z-index': selectedItem === section.items ? 3 : 2}">

  <div class="w-e-t-w-t pb-4">
    <div
      class="list-section-title-wrapper"
      [ngStyle]="{
       backgroundColor: sectionName !== SectionNames.SIDEBAR_SECTION ? tmTheme.sectionMainTitleBgColor : 'transparent'
    }">
      <sf-title-action-banner
        [template]="template"
        [page]="page"
        [pageIndex]="pageIndex"
        [section]="section"
        [sectionIndex]="sectionIndex"
        [sectionName]="sectionName"
        [isList]="true"
        [elRef]="ListSectionRef"
        [(selectedItem)]="selectedItem"
        (openRearrange$)="openRearrange$.emit()"
      />

      @if (section.title.icon.state) {
        <i class="{{section.title.icon.name}}"></i>
      }

      @if (section.title.state) {
        <h2
          contenteditable="true"
          [attr.placeholder]="section.title.pl | translate"
          [class.edit-mode]="selectedItem === section.title"
          [innerHTML]="section.title.text"
          (click)="onSelect(section.title)"
          (keydown.enter)="$event.preventDefault()"
          (blur)="onBlurSectionTitle($event, section)"
          (focus)="onFocusEL($event, section.title.text)"
          [sfContentEditableMaxLength]="appStateService.textMaxSizes.sectionMainTitle"
          (keydown)="elementKeydown($event, appStateService.textMaxSizes.sectionMainTitle)"
          [ngStyle]="{
           fontSize: appStateService.templateCurrentFontSize.sectionMainTitle + 'px',
           lineHeight: appStateService.templateCurrentFontSize.sectionMainTitleLineHeight + 'px',
           color: getH2Color()
       }"
        ></h2>
      }
    </div>
  </div>

  <div class="ul-wrapper">
    <div class="list-sec-ul-parent">
      <ul
        class="list-sec-ul"
        #ListSectionRef
        [class.edit-mode]="selectedItem === section.items"
        [class.skill-with-text]="section.type === TemplateSectionTypes.LANGUAGES && section.settings?.selectedShape?.type === TemplateSettingsShapes.TEXT_OPTIONS"
        [ngClass]="{
           'wrap-list': section.settings!.listTypeOnSidebar === 'wrap' && section.settings?.selectedShape!.infographicValue === '',
           'grid-list': section.settings!.listTypeOnSidebar === 'grid' && section.settings?.selectedShape!.infographicValue === '',
        }"
        (click)="onSelect(section.items)">

        @for (item of section.items; let listIndex = $index; let last = $last; let first = $first; track listIndex) {
          <li
            [ngStyle]="getCheapColor(section.settings?.selectedShape!.chipValue)"
            (click)="onSelectListItem(item, section.items)"
            class="{{sectionName === SectionNames.SIDEBAR_SECTION && last && section.settings?.listTypeOnSidebar === 'list' ? 'mb-0' : ''}}">

            <!------------ Banner Start ------------>
            @if (selectedItem === section.items && selectedListItem === item) {
              <sf-list-actions-banner
                [template]="template"

                [section]="section"
                [sectionName]="sectionName"
                [ListSectionRef]="ListSectionRef"

                [sectionItem]="item"
                [listIndex]="listIndex"
                [first]="first"
                [last]="last"
              />
            }
            <!------------ Banner End -------------->

            @if (section.settings!.selectedShape.hasIcon) {
              <i class="{{item.icon}}"></i>
            }

            <div
              class="list-text"
              contenteditable="true"
              [attr.placeholder]="(section.pl || '') | translate"
              (keydown.enter)="onAddListItem($event, section, listIndex, ListSectionRef)"
              (keydown.backspace)="onRemoveListItem($event, section.items, listIndex, ListSectionRef)"
              [innerHTML]="item.text"
              (blur)="onBlurListItem($event, item)"
              (focus)="onFocusListItem(item, section.items, item.text!)"
              (paste)="appStateService.onPastElementText($event, appStateService.textMaxSizes.listSectionItem)"
              [ngStyle]="{
              fontSize: appStateService.templateCurrentFontSize.listSectionContent + 'px',
              lineHeight: appStateService.templateCurrentFontSize.listSectionContentLineHeight + 'px',
            }"
            ></div>

            @if (section.settings?.selectedShape?.type === TemplateSettingsShapes.TEXT_OPTIONS) {
              <i class="lng-skill-text">{{ item.textValue }}</i>
            }

            @if (section.settings?.selectedShape!.infographicValue) {
              <sf-template-infographics
                [colors]="getInfColors(section, sectionName === SectionNames.SIDEBAR_SECTION)"
                [section]="section"
                [sectionItem]="item"
                (select$)="saveChanges()"
              />
            }
          </li>
        }
      </ul>
    </div>
  </div>
</div>

@if (!lastSection) {
  <div class="sf-line"></div>
}
