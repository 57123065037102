import {Router} from "@angular/router";
import {finalize} from "rxjs/operators";
import {NgOptimizedImage} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {TranslateModule} from "@ngx-translate/core";
import {TemplateService} from "@app/shared/services";
import {NzDividerComponent} from "ng-zorro-antd/divider";
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {DocumentTypes, TemplateTypes} from "@app/shared/enums";
import {AppStateService} from "@app/shared/services/app-state.service";
import {cloneObject, extractColorNumber, onChangeTemplateType} from "@app/shared/helpers";
import {Component, DestroyRef, inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Template, TemplateConfigs, TemplateSettingsThemeColor} from "@app/shared/interfaces";

interface TemplateType {
  src: string;
  name: string;
  type: TemplateTypes
}

interface ModalData {
  id?: number;
  images: string[];
}

@Component({
  selector: 'sf-tm-preview',
  templateUrl: './tm-preview.component.html',
  styleUrl: './tm-preview.component.scss',
  imports: [
    NzDividerComponent,
    NzTooltipDirective,
    NgOptimizedImage,
    TranslateModule
  ]
})
export class TmPreviewComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  private readonly nzModalService = inject(NzModalService);
  public readonly templateService = inject(TemplateService);
  private readonly appStateService = inject(AppStateService);

  protected templateTypes: TemplateType[] = [
    {
      src: 'assets/img/tm-layouts/tm-layout-sidebar.svg',
      name: 'tooltips.sidebar',
      type: TemplateTypes.SIDEBAR
    },
    {
      src: 'assets/img/tm-layouts/tm-layout-one-row.svg',
      name: 'tooltips.one_column',
      type: TemplateTypes.ONE_ROW
    },
    {
      src: 'assets/img/tm-layouts/tm-layout-two-rows.svg',
      name: 'tooltips.two_columns',
      type: TemplateTypes.TWO_ROWS
    }
  ];
  protected selectedTemplateType: TemplateType = this.templateTypes[0];

  protected modalData = {} as ModalData;

  @ViewChild('TmPreviewTitleRef', {static: true}) protected TmPreviewTitleRef!: TemplateRef<HTMLDivElement>;
  @ViewChild('TmPreviewRef', {static: true}) protected tmPreviewRef!: TemplateRef<HTMLDivElement>;

  protected template!: Template;

  protected templateColors: TemplateSettingsThemeColor[] = [];
  protected selectedColor: TemplateSettingsThemeColor | null = null;

  protected readonly DocumentTypes = DocumentTypes;

  protected btnLoaderState = false;

  constructor() {
  }

  ngOnInit() {
    this.listenToTmPreview();
  }

  private listenToTmPreview() {
    this.appStateService.templatePreview$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.template = res.template;
        this.selectedTemplateType = this.templateTypes.find((item) => item.type === (this.template.settings as TemplateConfigs).type)!;

        this.onPreviewTemplate(res.$event);
      });
  }

  protected onPreviewTemplate($event: Event) {
    $event.stopPropagation();

    this.templateColors = this.appStateService.getTmColors(this.template);
    this.selectedColor = this.templateColors[(extractColorNumber(this.template.src)! - 1) || 0];

    this.setModalData();

    this.nzModalService.create({
      nzMaskClosable: false,
      nzFooter: null,
      nzWidth: 800,
      nzTitle: this.TmPreviewTitleRef,
      nzWrapClassName: 'template-preview-cl2', // img-has-borders
      nzContent: this.tmPreviewRef,
      nzData: this.modalData
    });
  }

  protected onSelectTheme(color: TemplateSettingsThemeColor) {
    this.selectedColor = color;

    this.setModalData();
  }

  protected onChangeTemplateType(type: TemplateType) {
    this.selectedTemplateType = type;

    this.setModalData();
  }

  private setModalData() {
    const colorIndex = this.templateColors.findIndex((color) => color.id === this.selectedColor?.id);
    const id = this.template!.templateId;

    switch (this.template.documentType) {
      case DocumentTypes.CV: {
        const img = `assets/img/template-covers/tm-${id}/color-${colorIndex + 1}-${this.selectedTemplateType?.type}-page-1.png`;
        this.modalData = {
          id,
          images: [img, img.replace('-page-1.png', '-page-2.png')]
        }
        break;
      }
      case DocumentTypes.COVER: {
        const img = `assets/img/template-covers/cover-letter-${id}/color-${colorIndex + 1}-cover-letter.png`;
        this.modalData = {
          id,
          images: [img]
        }
        break;
      }
    }
  }

  protected onSelectTemplate() {
    if (this.appStateService.isAuthenticated) {
      this.btnLoaderState = true;

      const templateCopy = cloneObject(this.template);
      templateCopy.settings.settings.theme.colorId = this.selectedColor!.id;

      switch (this.template.documentType) {
        case DocumentTypes.CV: {
          const templateConfigs = (templateCopy.settings as TemplateConfigs);
          onChangeTemplateType(templateConfigs, this.selectedTemplateType!.type);
          break;
        }
        case DocumentTypes.COVER: {
          break;
        }
      }

      this.templateService.addTemplate(templateCopy)
        .pipe(
          finalize(() => this.btnLoaderState = false),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((res) => {
          this.router.navigate([this.appStateService.getLngRout('documents'), res.documentId]);
          this.nzModalService.closeAll();
        });
    } else {
      this.router.navigate(
        [this.appStateService.getLngRout('auth')],
        {
          queryParams: {
            returnUrl: this.appStateService.getLngRout(this.template.documentType === DocumentTypes.CV ? 'cv_templates' : 'cover_letters')
          }
        }
      );
    }
  }

}
