import {TagModule} from "primeng/tag";
import {Router} from "@angular/router";
import {finalize} from "rxjs/operators";
import {Template} from "@app/shared/interfaces";
import {NzSpinComponent} from "ng-zorro-antd/spin";
import {TranslateModule} from "@ngx-translate/core";
import {TmButtonsComponent} from "@app/shared/ui-kits";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {cloneObject, extractColorNumber} from "@app/shared/helpers";
import {AnalyticsService, TemplateService} from "@app/shared/services";
import {AppStateService} from "@app/shared/services/app-state.service";
import {Carousel, CarouselModule, CarouselResponsiveOptions} from "primeng/carousel";
import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'sf-resume-examples-section',
  templateUrl: './resume-examples-section.component.html',
  styleUrls: ['./resume-examples-section.component.scss'],
  imports: [
    TagModule,
    CarouselModule,
    NzSpinComponent,
    TmButtonsComponent,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResumeExamplesSectionComponent implements OnInit, OnDestroy {
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  private readonly templateService = inject(TemplateService);
  private readonly analyticsService = inject(AnalyticsService);
  protected readonly appStateService = inject(AppStateService);

  protected responsiveOptions: CarouselResponsiveOptions[] = [
    {
      breakpoint: '1600px',
      numVisible: 4,
      numScroll: 1
    },
    {
      breakpoint: '1300px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '800px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '520px',
      numVisible: 1,
      numScroll: 1
    },
  ];

  protected selectedTemplate: Template | null = null;

  ngOnInit() {
    /** This needed to enable vertical scroll on mobile */
    Carousel.prototype.onTouchMove = () => {
    };
  }

  ngOnDestroy() {
    this.selectedTemplate = null
  }

  protected getDotsNumber(carousel: Carousel) {
    return Array(this.appStateService.templatesForSlide.length - carousel.numVisible + 1);
  }

  protected onPreviewTemplate($event: Event, template: Template) {
    this.appStateService.templatePreview$.next({$event, template});
    this.analyticsService.track("Template Preview From Slider", {
      templateId: template.templateId,
      isPremiumTemplate: template.premium
    });
  }

  protected onSelectTemplate(template: Template) {
    this.selectedTemplate = template;

    if (this.appStateService.isAuthenticated) {
      const templateCopy = cloneObject(template);
      templateCopy.settings.settings.theme.colorId = this.appStateService.getTmColors(template)[(extractColorNumber(template.src)! - 1) || 0].id;

      this.templateService.addTemplate(templateCopy)
        .pipe(
          finalize(() => this.selectedTemplate = null),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((res) => {
          this.router.navigate([this.appStateService.getLngRout('documents'), res.documentId]);
        });

      this.analyticsService.track("Template Choose From Slider", {
        templateId: templateCopy.templateId,
        isPremiumTemplate: templateCopy.premium
      });
    } else {
      this.router.navigate(
        [this.appStateService.getLngRout('auth')],
        {queryParams: {returnUrl: this.appStateService.getLngRout('cv_templates')}}
      );
    }
  }

}

